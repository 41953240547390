import * as React from 'react';
import { Header, IconSize, Icons } from '@pdcfrontendui/components';
import { EmployeeMap, ShiftMap, TeamMap } from './api/model';
import { localeFormat } from './util/dates';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  TeamViewMode,
  getSiteRoutes,
  useTeamId,
  useTeamRouteParams,
} from './routes';
import ErrorBoundaryContainer from './sharedComponents/ErrorBoundaryContainer';
import FindSubstituteViewContainer from './FindSubstituteView/FindSubstituteViewContainer';
import ListViewContainer from './ListView/ListViewContainer';
import ShiftViewContainer from './ShiftView/ShiftViewContainer';
import { currentLanguage } from './currentLanguage';
import { connect } from './rootReducer';
import { setShowingRuleViolationInfo } from './appActions';
import CreateShift from './ListView/CreateShift';
import { EDITED_SHIFT_ID } from './constants';
import {
  EditedShift,
  editedShiftToTeamShift,
} from './ListView/CreateShift/EditedShift';
import { TeamShiftDef } from './api/TeamPlan_api';
import { setEditedShift } from './ShiftView/ShiftViewActions';
import { NO_TEAMS_LOADED } from './ChooseTeam/ChooseTeamReducer';

type StateFromProps = {
  screensizeBig: boolean;
  employeeMap: Record<string, EmployeeMap>;
  shifts: Record<string, ShiftMap>;
  teams: TeamMap;
  currentDate: Date;
  editedShift: EditedShift | null;
  shiftDefinitions: TeamShiftDef[];
};

type DispatchFromProps = {
  setEditedShift: (shift: EditedShift | null) => void;
  showRuleViolationInfo: () => void;
};

function Team({
  screensizeBig,
  employeeMap,
  shifts,
  showRuleViolationInfo,
  currentDate,
  editedShift,
  setEditedShift,
  shiftDefinitions,
  teams,
}: StateFromProps & DispatchFromProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const teamId = useTeamId();
  const { personId, shiftId = '', mode, tab } = useTeamRouteParams();
  if (!teamId || (teams !== NO_TEAMS_LOADED && !teams[teamId])) {
    return <Navigate to={getSiteRoutes().teams} replace />;
  }

  const teamHasPerson = !!employeeMap[teamId]?.[personId ?? ''];
  const isEditedShift = editedShift && shiftId === EDITED_SHIFT_ID;
  const shift = isEditedShift
    ? editedShiftToTeamShift(editedShift)
    : shifts[teamId]?.[shiftId];

  const isShiftSingleView =
    !screensizeBig &&
    (teamHasPerson ||
      (mode === TeamViewMode.FindSubstitute && shiftId === EDITED_SHIFT_ID)) &&
    !!shift;
  const listViewClassName = screensizeBig ? 'splitpane-left' : 'splitpane-one';
  const shiftViewClassName = isShiftSingleView
    ? 'splitpane-one'
    : 'splitpane-right';
  const shiftViewTitle = shift
    ? localeFormat(shift.period.from, false, currentLanguage.languageCode)
    : '';
  const findingSubstitute = mode === TeamViewMode.FindSubstitute;
  // If we are finding a substitute, and we have selected a possible employee, then the personId of selected shift does not match the personId in the url
  const assigningSubstitute =
    findingSubstitute &&
    !!shift &&
    personId !== undefined &&
    shift.personId !== personId;
  const createShift = (
    <CreateShift
      show={mode === TeamViewMode.CreateShift}
      isMobile={!screensizeBig}
      goBack={() => navigate(-1)}
      today={currentDate}
      className={screensizeBig ? 'splitpane-right' : ''}
      editedShift={editedShift}
      setEditedShift={(editedShift) => {
        setEditedShift(editedShift);
        navigate(
          getSiteRoutes().team(teamId, {
            tab,
            shiftId: EDITED_SHIFT_ID,
            mode: TeamViewMode.FindSubstitute,
          })
        );
      }}
      shiftTypes={shiftDefinitions}
      title={currentLanguage.CreateNew}
      submitText={currentLanguage.Next}
      backText={currentLanguage.Cancel}
    />
  );
  return (
    <div className="splitpane">
      {!isShiftSingleView && (
        <div className={listViewClassName}>
          <ErrorBoundaryContainer>
            <ListViewContainer
              key={teamId} // Run cleanup in ListView when changing team
              teamId={teamId}
            />
          </ErrorBoundaryContainer>
        </div>
      )}
      {isShiftSingleView || screensizeBig ? (
        <div className={shiftViewClassName}>
          <div className="container">
            <Header centerize>
              {screensizeBig && <Header.Spacer />}
              {(isShiftSingleView || findingSubstitute) && (
                <Header.Left>
                  <Icons.ChevronLeft
                    size={IconSize.XSmall}
                    onClick={() => navigate(-1)}
                  />
                </Header.Left>
              )}
              <Header.Title>{shiftViewTitle}</Header.Title>
              {findingSubstitute && !assigningSubstitute && (
                <Header.Right>
                  <Icons.Info
                    size={IconSize.XSmall}
                    onClick={showRuleViolationInfo}
                  />
                </Header.Right>
              )}
            </Header>
            {!!shift?.personId && shiftId && (
              <ErrorBoundaryContainer>
                {findingSubstitute && !assigningSubstitute ? (
                  <FindSubstituteViewContainer shiftId={shiftId} />
                ) : (
                  <ShiftViewContainer
                    key={personId ?? shift.personId}
                    teamId={teamId}
                    personId={personId ?? shift.personId}
                    shiftId={shiftId}
                    location={location}
                  />
                )}
              </ErrorBoundaryContainer>
            )}
          </div>
          {createShift}
        </div>
      ) : (
        createShift
      )}
    </div>
  );
}

export const TeamContainer = connect<StateFromProps, DispatchFromProps>(
  (store) => ({
    teams: store.chooseTeamReducer.teams,
    screensizeBig: store.appReducer.screensizeBig,
    employeeMap: store.listViewReducer.employeeMap,
    shifts: store.listViewReducer.shiftMap,
    currentDate: store.appReducer.globalSettings.useDateTime,
    editedShift: store.shiftViewReducer.editedShift,
    shiftDefinitions: store.appReducer.creatableShifts,
  }),
  (dispatch) => ({
    showRuleViolationInfo: () => {
      dispatch(setShowingRuleViolationInfo(true));
    },
    setEditedShift: (shift) => {
      dispatch(setEditedShift(shift));
    },
  })
)(Team);
