import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Store } from '../rootReducer';
import { languageSelect } from '../Settings/SettingsActions';
import LanguageSelect, {
  DispatchFromProps,
  StateFromProps
} from './LanguageSelect';

const mapStateToProps = (store: Store): StateFromProps => ({
  language: store.settingsReducer.language
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setLanguage: (newLanguage: string) => {
    dispatch(languageSelect(newLanguage));
  }
});

export default connect<StateFromProps, DispatchFromProps, {}, Store>(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelect);
