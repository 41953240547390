import {
  Modal,
  Button,
  ButtonStyle,
  LoadingButton,
  InlineNotification,
  NotificationType,
  IconSize,
} from '@pdcfrontendui/components';
import React, { useMemo } from 'react';
import { currentLanguage } from '../currentLanguage';
import { TeamShift } from '../api/TeamPlan_api';
import { localeFormatLongDay } from '../util/dates';
import './ConfirmDeleteModal.scss';

const scss = {
  ConfirmDeleteModal: 'ConfirmDeleteModal',
  header: 'header',
  icon: 'icon',
  body: 'body',
  deviatingPayer: 'deviatingPayer',
};

type Props = {
  shown: boolean;
  shift: TeamShift;
  isOffer: boolean;
  loading: boolean;
  onCancel: () => void;
  onOk: (shiftTimeLabel: string) => void;
};

const ConfirmDeleteModal = ({
  shown,
  shift,
  isOffer,
  loading,
  onCancel,
  onOk,
}: Props) => {
  const shiftTimeLabel = useMemo(
    () =>
      localeFormatLongDay(
        shift.period.from,
        true,
        currentLanguage.languageCode
      ),
    [shift.period.from]
  );

  return (
    <Modal shown={shown} className={scss.ConfirmDeleteModal}>
      <Modal.Header className="header">
        {currentLanguage.ConfirmRemoveShiftTitle}
      </Modal.Header>
      <Modal.Icon
        iconKey="ExclamationFill"
        className="icon"
        size={IconSize.XSmall}
      />
      <Modal.Body>
        <div>{`${currentLanguage.ConfirmRemoveShiftBody_2(
          shift.label,
          shiftTimeLabel
        )}.\n${
          isOffer ? currentLanguage.ConfirmRemoveShiftBodyHandover : ''
        }`}</div>
        {shift.deviatingPayerUiLabel && (
          <InlineNotification
            type={NotificationType.Error}
            className={scss.deviatingPayer}
          >
            <div>{currentLanguage.DeviatingPayerNotificationTitle}</div>
            <div>
              {currentLanguage.DeviatingPayerNotificationBody_1(
                shift.deviatingPayerUiLabel
              )}
            </div>
          </InlineNotification>
        )}
      </Modal.Body>
      <Modal.Buttons>
        <Button variant={ButtonStyle.Secondary} danger onClick={onCancel}>
          {currentLanguage.Cancel}
        </Button>
        <LoadingButton
          variant={ButtonStyle.Primary}
          danger
          onClick={() => onOk(shiftTimeLabel)}
          loading={loading}
        >
          {currentLanguage.Delete}
        </LoadingButton>
      </Modal.Buttons>
    </Modal>
  );
};

export default ConfirmDeleteModal;
