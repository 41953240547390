import { Dispatch } from 'redux';
import getApi from '../getApi';

export type SettingAction =
  | {
      type: 'LANGUAGE_SELECT';
      language: string;
    }
  | {
      type: 'GETALLOWNOTIFICATIONS_REQUEST';
    }
  | {
      type: 'GETALLOWNOTIFICATIONS_SUCCESS';
      allowNotification: boolean;
    }
  | {
      type: 'GETALLOWNOTIFICATIONS_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'SETALLOWNOTIFICATIONS_REQUEST';
    }
  | {
      type: 'SETALLOWNOTIFICATIONS_SUCCESS';
      allowNotification: boolean;
    }
  | {
      type: 'SETALLOWNOTIFICATIONS_FAILURE';
      errorMessage: string;
    };
export const languageSelect = (language: string): SettingAction => ({
  type: 'LANGUAGE_SELECT',
  language,
});

export const getAllowNotificationsRequest = (): SettingAction => ({
  type: 'GETALLOWNOTIFICATIONS_REQUEST',
});

export const getAllowNotificationsSuccess = (
  allowNotification: boolean
): SettingAction => ({
  type: 'GETALLOWNOTIFICATIONS_SUCCESS',
  allowNotification,
});

export const getAllowNotificationsFailure = (
  errorMessage: string
): SettingAction => ({
  type: 'GETALLOWNOTIFICATIONS_FAILURE',
  errorMessage,
});

export const setAllowNotificationsRequest = (): SettingAction => ({
  type: 'SETALLOWNOTIFICATIONS_REQUEST',
});
export const setAllowNotificationsSuccess = (
  allowNotification: boolean
): SettingAction => ({
  type: 'SETALLOWNOTIFICATIONS_SUCCESS',
  allowNotification,
});
export const setAllowNotificationsFailure = (
  errorMessage: string
): SettingAction => ({
  type: 'SETALLOWNOTIFICATIONS_FAILURE',
  errorMessage,
});

export function attemptGetAllowNotifications() {
  // example using Api
  return (dispatch: Dispatch) => {
    dispatch(getAllowNotificationsRequest());
    return getApi()
      .getAllowNotifications()
      .then((res) => {
        dispatch(getAllowNotificationsSuccess(res));
      })
      .catch((err) => {
        dispatch(getAllowNotificationsFailure(err.message));
      });
  };
}
export function attemptSetAllowNotifications(allowNotification: boolean) {
  // example using Api
  return (dispatch: Dispatch) => {
    dispatch(setAllowNotificationsRequest());
    return getApi()
      .setAllowNotifications(allowNotification)
      .then((res) => {
        if (res) {
          dispatch(setAllowNotificationsSuccess(allowNotification));
        }
      })
      .catch((err) => {
        dispatch(setAllowNotificationsFailure(err.message));
      });
  };
}
