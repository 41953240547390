//
// Access to the api happens through getApi()
//

import { Api } from './api/Api';

let api: Api | null = null;

// XXX This string corresponds to a specified view in the database, for now we
// only have the option `teamplan_shiftdetails`.  This view changes which
// feilds will be in the record.fieldlist on each shift recieved from the
// backend.
//
// Using option `teamplan_shiftdetails`, will just give a field called 'remark'.
// More could be added later if a specific customer wants specific details on
// their shifts.
const tc = {
  storedViewName: 'teamplan_shiftdetails',
};

export default function getApi(): Api {
  if (api) {
    return api;
  }

  const base = document.baseURI
    .toString()
    .replace(/#.*/, '')
    .replace(/\/+$/, '')
    .toLowerCase();

  // Default webservice URLs
  const apiUrl = `${base}/api`;

  api = new Api(
    apiUrl,
    // TODO It may be better to get this value from elsewhere
    tc.storedViewName
  );

  return api;
}
