import {
  LocalStorageItemType,
  LocalStorageKey,
  localStorageSchemas,
} from './LocalStorageKey';

import { addWindowListener } from '@pdcfrontendui/utils';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

const prefix = 'teamplan-';

export function localStorageSet<T extends LocalStorageKey>(
  key: T,
  value: LocalStorageItemType<T>
) {
  const prefixedKey = prefix + key;
  try {
    localStorage.setItem(prefixedKey, JSON.stringify(value));
  } catch (e) {
    /* ignore error */
  }
}

// Caching is necessary because parsing an object produces a new reference each time.
// That would cause useLocalStorage to go into an infinite loop.
// Probably also saves some CPU cycles.
const localStorageCache: {
  [P in LocalStorageKey]?: {
    raw: string;
    parsed: LocalStorageItemType<P>;
  };
} = {};

export function localStorageGet<T extends LocalStorageKey>(
  key: T
): LocalStorageItemType<T> | null {
  const prefixedKey = prefix + key;
  try {
    const raw = localStorage.getItem(prefixedKey);
    if (typeof raw !== 'string') {
      return null;
    }
    const cached = localStorageCache[key];
    if (cached?.raw === raw) {
      return cached.parsed;
    }
    const parsed = localStorageSchemas[key].parse(JSON.parse(raw));
    (
      localStorageCache as Record<
        T,
        {
          raw: string;
          parsed: LocalStorageItemType<T>;
        }
      >
    )[key] = { raw, parsed };
    return parsed;
  } catch (e) {
    return null;
  }
}

export function localStorageRemove(key: LocalStorageKey): void {
  const prefixedKey = prefix + key;
  try {
    localStorage.removeItem(prefixedKey);
  } catch (e) {
    /* ignore error */
  }
}

export function useLocalStorage<T extends LocalStorageKey>(key: T) {
  const prefixedKey = prefix + key;
  return useSyncExternalStore(
    (updateValue) =>
      addWindowListener('storage', (e) => {
        if (e.storageArea === localStorage && e.key === prefixedKey) {
          updateValue();
        }
      }),
    () => localStorageGet(key)
  );
}
