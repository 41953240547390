import { EmployeeMap, ShiftMap } from '../api/model';
import { Period, toDefaultLoadPeriod } from '../util/dates';

import { ActionType } from '../ActionType';
import { AppAction } from '../appActions';
import { CommonAction } from '../commonAction';
import { ListViewAction } from './ListViewActions';
import { LocalStorageKey } from '../util/LocalStorageKey';
import { LoginAction } from '../Login/LoginAction';
import { Reducer } from 'redux';
import { TeamShiftStatusEnum } from '../api/enumLib_api';
import { localStorageGet } from '../util/localStorage';

type MenuItemLoadedType = Record<
  string,
  { favoriteStatus: boolean; notification: boolean }
>;

export type ListViewReducer = {
  shiftMap: Record<string, ShiftMap>;
  employeeMap: Record<string, EmployeeMap>;
  actionRequiredCounters: Record<string, number>;
  menuItemLoading: MenuItemLoadedType;
  /**
   * Prefer using teamId from url instead where possible
   */
  currentTeam: string;
  loading: boolean;
  backLoading: boolean;
  loadingPeriods: Period[]; // TODO Maybe we don't need this - we just need to know if we load from top or bottom
  loadingEmployees: boolean;
  period: Period;
  dayLabelStickyDate: Date;
  selectedShiftId: string;
  errorMessage?: string;
};

const initialState: ListViewReducer = {
  shiftMap: {},
  employeeMap: {},
  actionRequiredCounters: {},
  menuItemLoading: {},
  currentTeam: localStorageGet(LocalStorageKey.currentTeam) ?? '',
  loading: false,
  backLoading: false,
  loadingPeriods: [],
  loadingEmployees: false,
  period: toDefaultLoadPeriod(new Date()),
  dayLabelStickyDate: new Date(),
  selectedShiftId: '',
  errorMessage: undefined,
};

function calculateActionRequiredCounters(
  state: ListViewReducer
): Record<string, number> {
  const actionRequiredCounters: Record<string, number> = {};
  for (const [teamId, shifts] of Object.entries(state.shiftMap)) {
    actionRequiredCounters[teamId] = 0;
    for (const shift of Object.values(shifts)) {
      if (
        shift.status === TeamShiftStatusEnum.actionRequired ||
        shift.status === TeamShiftStatusEnum.offerActionRequired ||
        shift.status === TeamShiftStatusEnum.swapActionRequired ||
        shift.status === TeamShiftStatusEnum.offered
      ) {
        actionRequiredCounters[teamId] += 1;
      }
    }
  }
  return actionRequiredCounters;
}

const listViewReducer: Reducer<ListViewReducer> = (
  state: ListViewReducer = initialState,
  action: ListViewAction | CommonAction | AppAction | LoginAction
) => {
  switch (action.type) {
    case ActionType.INIT:
      return {
        ...initialState,
        period: state.period,
        currentTeam: localStorageGet(LocalStorageKey.currentTeam) ?? '',
      };
    case 'LOADTEAMROSTER_REQUEST':
    case 'LOADTEAMROSTERMULTI_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'LOADTEAMROSTER_SUCCESS': {
      const currentEmployeeMap = state.employeeMap[action.teamId];
      const newEmployeeMap = action.employees;

      const employeeMap = {
        ...state.employeeMap,
        [action.teamId]: { ...currentEmployeeMap, ...newEmployeeMap },
      };

      const newState: ListViewReducer = {
        ...state,
        loading: false,
        employeeMap: employeeMap,
        shiftMap: {
          ...state.shiftMap,
          [action.teamId]: action.shifts,
        },
      };
      newState.actionRequiredCounters =
        calculateActionRequiredCounters(newState);
      return newState;
    }
    case 'LOADTEAMROSTER_FAILURE':
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
        currentTeam: action.teamId,
      };
    case 'CHANGE_PERIOD': {
      const newState = {
        ...state,
        period: action.period,
      };
      newState.actionRequiredCounters =
        calculateActionRequiredCounters(newState);
      return newState;
    }
    case 'ADD_LOADING_PERIOD': {
      const loadingPeriods = [...state.loadingPeriods];
      loadingPeriods.push(action.period);
      return {
        ...state,
        loadingPeriods,
      };
    }
    case 'REMOVE_LOADING_PERIOD': {
      const loadingPeriods = [...state.loadingPeriods];
      const index = loadingPeriods.indexOf(action.period);
      if (index !== -1) {
        loadingPeriods.splice(index, 1);
      }
      return {
        ...state,
        loadingPeriods,
      };
    }

    case 'CHANGE_TEAM': {
      return {
        ...state,
        currentTeam: action.teamId,
        period: state.period,
      };
    }

    case 'UPDATE_SHIFT': {
      const newShifts = { ...(state.shiftMap[action.teamId] ?? {}) };
      if (action.shift.id !== action.shiftId) {
        throw new Error('ids dont match');
      } else {
        newShifts[action.shiftId] = action.shift;
      }
      const newState = {
        ...state,
        shiftMap: {
          ...state.shiftMap,
          [action.teamId]: newShifts,
        },
      };
      newState.actionRequiredCounters =
        calculateActionRequiredCounters(newState);
      return newState;
    }
    case 'UPDATE_SHIFTS': {
      const newShifts = { ...(state.shiftMap[action.teamId] ?? {}) };
      if (action.shifts.filter((e) => e.id === action.shiftId).length <= 0) {
        throw new Error('ids dont match');
      }
      for (const shift of action.shifts) {
        newShifts[shift.id] = shift;
      }
      const newState = {
        ...state,
        shiftMap: {
          ...state.shiftMap,
          [action.teamId]: newShifts,
        },
      };
      newState.actionRequiredCounters =
        calculateActionRequiredCounters(newState);
      return newState;
    }
    case 'SET_BACKLOADING': {
      return {
        ...state,
        backLoading: action.value,
      };
    }
    case 'GETEMPLOYEES_REQUEST':
      return {
        ...state,
        loading: true,
        loadingEmployees: true,
      };
    case 'GETEMPLOYEES_SUCCESS': {
      const currentEmployeeMap = state.employeeMap[action.teamId];
      const newEmployeeMap = action.getEmployeesResponse;

      const employeeMap = {
        ...state.employeeMap,
        [action.teamId]: { ...currentEmployeeMap, ...newEmployeeMap },
      };

      return {
        ...state,
        loading: false,
        loadingEmployees: false,
        employeeMap,
      };
    }
    case 'GETEMPLOYEES_FAILURE':
      return {
        ...state,
        loading: false,
        loadingEmployees: false,
      };
    case 'UPDATE_DAYLABELSTICKY':
      return {
        ...state,
        dayLabelStickyDate: action.date,
      };
    case 'UPDATE_SELECTEDSHIFT':
      return {
        ...state,
        selectedShiftId: action.selectedShiftId,
      };

    case ActionType.GETGLOBALSETTINGS_SUCCESS:
      return {
        ...state,
        // When app loads, and GetGlobalSettings is called, dayLabelStickyDate will align with AppReducer's activeDate and useDateTime
        dayLabelStickyDate: action.globalSettings.useDateTime,
        // Match the period to what will be loaded by default
        period: toDefaultLoadPeriod(action.globalSettings.useDateTime),
      };
  }

  return state;
};

export default listViewReducer;

