import {
  CREATE_SHIFT_DURATION_MAX_DAYS,
  CREATE_SHIFT_FUTURE_MAX_DAYS,
  SHIFT_TYPE_SUGGESTIONS_MAX,
} from '../../constants';
import { useCallback, useEffect, useMemo } from 'react';
import { addDays, eachDay, startOfDay } from 'date-fns';
import {
  dateFormats,
  padDateOrTime as pad,
  dayKey,
} from '@pdcfrontendui/utils';
import { useBool } from '@pdcfrontendui/hooks';
import { EditedShift } from './EditedShift';
import type {
  SearchableSelectGroup,
  SearchableSelectOption,
  SelectOption,
} from '@pdcfrontendui/components';
import { TeamDuty, TeamShiftDef } from '../../api/TeamPlan_api';
import {
  applyMinuteOffsetInterval,
  formatMinuteOffsetInterval,
} from '../../util/minuteOffset';
import { currentLanguage } from '../../currentLanguage';
import { toIdMap } from '../../api/model';
import { useFormik } from 'formik';

export type FormValues = {
  fromHours: string | null;
  fromMinutes: string | null;
  toHours: string | null;
  toMinutes: string | null;
  /** Start date. Time not used. */
  startDate: Date;
  /** How many days the shift extends. If 0, shift is exactly or less than 24 hours */
  extendNumDays: number;
  shiftTypeKey: string;
  dutyLines?: TeamDuty[];
};

function fieldsAreValid(values: FormValues): boolean {
  return (
    !!values.shiftTypeKey &&
    !!values.fromHours &&
    !!values.fromMinutes &&
    !!values.toHours &&
    !!values.toMinutes
  );
}

function getNumExtendedDays(from: Date, to: Date): number {
  let days = (to.getTime() / 60_000 - from.getTime() / 60_000) / 1440;
  // If it's an integer, then we subtract one since it doesn't actually extend to the next day
  if (Math.round(days) === days) {
    days -= 1;
  }
  return Math.floor(days);
}

export function getDateOptions(
  start: Date,
  numDays: number,
  format: (date: Date) => string
): SelectOption[] {
  const floored = startOfDay(start);
  const dates = eachDay(floored, addDays(floored, numDays - 1));
  return dates.map((date) => ({
    key: dayKey(date),
    value: dayKey(date),
    label: format(date),
  }));
}

export const SUGGESTION_PREFIX = 'SUGGEST_';

export function applyFormValuesToEditedShift(
  editedShift: EditedShift,
  shiftDefMap: Record<string, TeamShiftDef>,
  values: FormValues
): EditedShift | null {
  if (!fieldsAreValid(values)) {
    return null;
  }
  const def = shiftDefMap[values.shiftTypeKey] ?? null;
  const canSelectEndDate = !!def?.editable && def.dutyLines.length > 1;
  const fromHoursNum = Number(values.fromHours);
  const fromMinutesNum = Number(values.fromMinutes);
  const toHoursNum = Number(values.toHours);
  const toMinutesNum = Number(values.toMinutes);
  const addedDays =
    (canSelectEndDate ? values.extendNumDays : 0) +
    (toIsBeforeFrom(values) ? 1 : 0);
  const to = addDays(values.startDate, addedDays);
  to.setHours(toHoursNum, toMinutesNum);

  return {
    ...editedShift,
    isDefault: false,
    def,
    dutyLines: values.dutyLines?.length
      ? values.dutyLines
      : editedShift.dutyLines.length
      ? editedShift.dutyLines
      : def?.dutyLines ?? [],
    from: new Date(
      values.startDate.getFullYear(),
      values.startDate.getMonth(),
      values.startDate.getDate(),
      fromHoursNum,
      fromMinutesNum
    ),
    to,
  };
}

function toIsBeforeFrom(values: FormValues): boolean {
  return (
    !!values.fromHours &&
    !!values.fromMinutes &&
    !!values.toHours &&
    !!values.toMinutes &&
    values.toHours !== '24' &&
    `${pad(values.fromHours)}:${pad(values.fromMinutes)}` >=
      `${pad(values.toHours)}:${pad(values.toMinutes)}`
  );
}

function validNumberInput(
  value: string | null,
  min: number,
  max: number
): boolean {
  if (!value) {
    return true;
  }
  const num = Number(value);
  return num >= min && num <= max;
}

export function useEditShift(
  today: Date,
  shiftDefs: TeamShiftDef[],
  editedShift: EditedShift | null,
  onSubmit: (editedShift: EditedShift) => Promise<void>,
  teamId: string,
  shiftTypeSuggestionsMap: Record<string, string[]> | null,
  setShiftTypeSuggestions: (map: Record<string, string[]>) => void
) {
  const defaultEditedShift: EditedShift = useMemo(
    () => ({
      from: today,
      to: today,
      originalId: null,
      def: null,
      dutyLines: [],
      isDefault: true,
    }),
    [today]
  );

  editedShift ??= defaultEditedShift;

  const shiftDefMap = useMemo(() => toIdMap(shiftDefs), [shiftDefs]);

  const initialValues: FormValues = useMemo(() => {
    // defaultEditedShift has dummy values, so ignore those
    const hasValidDates = !editedShift.isDefault;
    const toHours = hasValidDates ? pad(editedShift.to.getHours()) : null;
    const toMinutes = hasValidDates ? pad(editedShift.to.getMinutes()) : null;
    return {
      fromHours: hasValidDates ? pad(editedShift.from.getHours()) : null,
      fromMinutes: hasValidDates ? pad(editedShift.from.getMinutes()) : null,
      toHours: toHours === '00' && toMinutes === '00' ? '24' : toHours,
      toMinutes,
      shiftTypeKey: editedShift.def?.id ?? '',
      startDate: editedShift.from,
      dutyLines: editedShift.dutyLines,
      extendNumDays: hasValidDates
        ? getNumExtendedDays(editedShift.from, editedShift.to)
        : 0,
    };
  }, [editedShift]);

  const { values, setValues, isValid, submitForm, resetForm, isSubmitting } =
    useFormik<FormValues>({
      enableReinitialize: true,
      validateOnMount: true,
      initialValues,
      validate: (values) => {
        if (!fieldsAreValid(values) || !shiftDefMap[values.shiftTypeKey]) {
          // Dummy since we don't need to display any errors, and Formik measures validity by the presence of errors
          return { dummy: 'dummy' };
        }
        return {};
      },
      onSubmit: async (values) => {
        const draft = applyFormValuesToEditedShift(
          editedShift,
          shiftDefMap,
          values
        );
        if (draft) {
          await onSubmit(draft);
        }
      },
    });

  const shiftDef = shiftDefMap[values.shiftTypeKey];
  const timeEditable = !!shiftDef?.editable;
  // Actual dutylines may have been modified such that length is shorter than the shiftDef, so the def should determine this
  const canSelectEndDate = timeEditable && shiftDef.dutyLines.length > 1;

  const setFromHours = async (fromHours: string | null) => {
    if (timeEditable && validNumberInput(fromHours, 0, 23)) {
      await setValues((values) => {
        return { ...values, fromHours };
      });
    }
  };

  const setFromMinutes = async (fromMinutes: string | null) => {
    if (timeEditable && validNumberInput(fromMinutes, 0, 59)) {
      await setValues((values) => {
        return { ...values, fromMinutes };
      });
    }
  };

  const setToHours = async (toHours: string | null) => {
    if (timeEditable && validNumberInput(toHours, 0, 24)) {
      await setValues((values) => {
        return {
          ...values,
          toHours,
          toMinutes: toHours === '24' ? '00' : values.toMinutes,
        };
      });
    }
  };

  const setToMinutes = async (toMinutes: string | null) => {
    if (
      timeEditable &&
      values.toHours !== '24' &&
      validNumberInput(toMinutes, 0, 59)
    ) {
      await setValues((values) => {
        return { ...values, toMinutes };
      });
    }
  };

  const setStartDate = async (startDate: Date) => {
    await setValues((values) => ({ ...values, startDate }));
  };
  const setExtendBy = useCallback(
    async (endDate: string) => {
      if (
        timeEditable &&
        validNumberInput(endDate, 0, CREATE_SHIFT_DURATION_MAX_DAYS - 1)
      ) {
        await setValues((values) => ({
          ...values,
          extendNumDays: Number(endDate),
        }));
      }
    },
    [setValues, timeEditable]
  );

  const setShiftTypeKey = async (value: string) => {
    const shiftTypeKey = value.replace(SUGGESTION_PREFIX, '');
    const newShiftDef = shiftDefMap[shiftTypeKey] ?? null;
    if (newShiftDef) {
      await setValues((values) => {
        const newPeriod = applyMinuteOffsetInterval(
          values.startDate,
          newShiftDef.startTime,
          newShiftDef.endTime
        );
        const toHours = pad(newPeriod.to.getHours());
        const toMinutes = pad(newPeriod.to.getMinutes());
        const extendNumDays = getNumExtendedDays(newPeriod.from, newPeriod.to);
        return {
          ...values,
          startDate: startOfDay(newPeriod.from),
          endDate: startOfDay(newPeriod.to),
          fromHours: pad(newPeriod.from.getHours()),
          fromMinutes: pad(newPeriod.from.getMinutes()),
          toHours: toHours === '00' && toMinutes === '00' ? '24' : toHours,
          toMinutes,
          shiftTypeKey,
          dutyLines: newShiftDef.dutyLines,
          extendNumDays,
        };
      });
      const shiftTypeSuggestions = shiftTypeSuggestionsMap?.[teamId];
      const newSuggested = shiftTypeSuggestions
        ? [
            shiftTypeKey,
            ...shiftTypeSuggestions
              .filter(
                (suggestion) =>
                  suggestion in shiftDefMap && suggestion !== shiftTypeKey
              )
              .slice(0, SHIFT_TYPE_SUGGESTIONS_MAX - 1),
          ]
        : [shiftTypeKey];
      setShiftTypeSuggestions({
        ...shiftTypeSuggestionsMap,
        [teamId]: newSuggested,
      });
    }
  };

  const isOverlapping = toIsBeforeFrom(values);

  const [_shouldShowEndDateSelect, showEndDateSelect] = useBool(false);
  const shouldShowEndDateSelect = _shouldShowEndDateSelect || canSelectEndDate;

  const shiftOptions: SearchableSelectGroup[] = useMemo(() => {
    const options: SearchableSelectGroup[] = [];
    const shiftTypeSuggestions = shiftTypeSuggestionsMap?.[teamId];
    if (shiftTypeSuggestions) {
      const subOptions = shiftTypeSuggestions
        .filter((suggestion) => suggestion in shiftDefMap)
        .map((suggestion): SearchableSelectOption => {
          const shiftDef = shiftDefMap[suggestion]!;
          return {
            label: shiftDef.label,
            value: SUGGESTION_PREFIX + suggestion,
            subLabel: formatMinuteOffsetInterval(
              shiftDef.startTime,
              shiftDef.endTime
            ),
          };
        });
      if (subOptions.length > 0) {
        options.push({
          label: currentLanguage.RecentlyUsedShiftDefs,
          options: subOptions,
        });
      }
    }
    options.push({
      options: shiftDefs.map((shiftDef) => ({
        label: shiftDef.label,
        value: shiftDef.id,
        subLabel: formatMinuteOffsetInterval(
          shiftDef.startTime,
          shiftDef.endTime
        ),
      })),
      label: currentLanguage.shift_plural,
    });
    return options;
  }, [shiftDefs, shiftDefMap, teamId, shiftTypeSuggestionsMap]);

  const startDateOptions = useMemo(
    () =>
      getDateOptions(
        today,
        CREATE_SHIFT_FUTURE_MAX_DAYS,
        dateFormats.Man_31DOT_okt_1999
      ),
    [today]
  );

  const extendByOptions = useMemo(
    () =>
      Array.from({ length: CREATE_SHIFT_DURATION_MAX_DAYS }, (_, i) => ({
        key: i.toString(),
        value: i.toString(),
        label: dateFormats.$31DOT_okt(
          addDays(values.startDate, (isOverlapping ? 1 : 0) + i)
        ),
      })),
    [values.startDate, isOverlapping]
  );

  const endDate = addDays(
    values.startDate,
    values.extendNumDays + (isOverlapping ? 1 : 0)
  );

  useEffect(() => {
    resetForm();
  }, [initialValues, resetForm]);

  return {
    ...values,
    setFromHours,
    setFromMinutes,
    setToHours,
    setToMinutes,
    setExtendBy,
    isOverlapping,
    isValid,
    setShiftTypeKey,
    submitForm,
    setStartDate,
    extendByOptions,
    startDateOptions,
    timeEditable,
    shiftOptions,
    shouldShowEndDateSelect,
    showEndDateSelect,
    canSelectEndDate,
    endDate,
    isSubmitting,
  };
}
