import './FindSubstituteView.scss';

import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import { CallIn } from '../api/TeamPlan_api';
import { EDITED_SHIFT_ID } from '../constants';
import EmployeeItem from '../components/EmployeeItem';
import { EmployeeMap } from '../api/model';
import NoCandidates from '../components/NoCandidates';
import ShiftsLoadingState from '../components/ShiftsLoadingState';
import { currentLanguage } from '../currentLanguage';
import { useLocation, useNavigate } from 'react-router-dom';
import { TeamViewMode, getSiteRoutes, getTeamRouteParams } from '../routes';
import { FindSubstituteViewProps } from './FindSubstituteViewContainer';

export type StateFromProps = {
  loading: boolean;
  substitutes: CallIn[];
  teamId: string;
  employees: EmployeeMap;
  loadedForShift: string;
};

export type DispatchFromProps = {
  loadSubstitutes: (shiftId: string, teamId: string) => void;
  setLoadedForShift: (id: string) => void;
  resetSubstituteStore: () => void;
  getEmployees: (personIds: number[], teamId: string) => void;
};

const FindSubstituteView = ({
  loadedForShift,
  shiftId,
  teamId,
  employees,
  substitutes,
  loading,
  resetSubstituteStore,
  loadSubstitutes,
  setLoadedForShift,
  getEmployees,
}: DispatchFromProps & StateFromProps & FindSubstituteViewProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollbarWidth, setScrollbarWidth] = React.useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const fictiveEmployeeIds = useMemo(
    () =>
      Object.values(employees)
        .filter((employee) => employee.isFictive)
        .map((employee) => employee.id),
    [employees]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (ref.current) {
      setScrollbarWidth(ref.current.offsetWidth - ref.current.clientWidth);
    }
  });
  useEffect(() => {
    if (loadedForShift !== shiftId || shiftId === EDITED_SHIFT_ID) {
      resetSubstituteStore();
      loadSubstitutes(shiftId, teamId);
      setLoadedForShift(shiftId);
    }
  }, [
    loadSubstitutes,
    loadedForShift,
    resetSubstituteStore,
    setLoadedForShift,
    shiftId,
    teamId,
  ]);

  useEffect(() => {
    const missingSubs: number[] = [];
    substitutes.forEach((substitute) => {
      if (!employees[substitute.id] && missingSubs.includes(substitute.id)) {
        missingSubs.push(substitute.id);
      }
    });
    if (missingSubs.length > 0) {
      getEmployees(missingSubs, teamId);
    }
  }, [employees, getEmployees, substitutes, teamId]);

  const onEmployeeSelect = (personId: number) => {
    navigate(
      getSiteRoutes().team(teamId, {
        ...getTeamRouteParams(location.search),
        shiftId,
        personId,
        mode: TeamViewMode.FindSubstitute,
      })
    );
  };

  const { assignableSubstitutes, unassignableSubstitutes, fictiveSubstitutes } =
    useMemo(() => {
      const assignableSubstitutes: CallIn[] = [];
      const unassignableSubstitutes: CallIn[] = [];
      const fictiveSubstitutes: CallIn[] = [];
      for (const substitute of substitutes) {
        if (fictiveEmployeeIds.includes(substitute.id)) {
          // Don't show fictive substitutes that are not assignable
          if (substitute.assignable) {
            fictiveSubstitutes.push(substitute);
          }
        } else if (substitute.assignable) {
          assignableSubstitutes.push(substitute);
        } else {
          unassignableSubstitutes.push(substitute);
        }
      }
      return {
        assignableSubstitutes,
        unassignableSubstitutes,
        fictiveSubstitutes,
      };
    }, [substitutes, fictiveEmployeeIds]);

  return (
    <div
      className="findsubstituteview"
      ref={ref}
      style={{ paddingRight: `${Math.max(12 - scrollbarWidth, 0)}px` }}
    >
      {!loading && <div className="list-label">{currentLanguage.callList}</div>}
      {loading ? (
        <ShiftsLoadingState />
      ) : assignableSubstitutes.length < 1 ? (
        <NoCandidates className="empty" />
      ) : (
        assignableSubstitutes.map((substitute) => (
          <EmployeeItem
            key={substitute.id}
            id={substitute.id}
            name={employees[substitute.id]?.name ?? ''}
            status={substitute.shortRule}
            priority={substitute.priority}
            onItemClick={() => onEmployeeSelect(substitute.id)}
          />
        ))
      )}
      {!!fictiveSubstitutes.length && (
        <>
          <div className="list-label">{currentLanguage.FictiveEmployees}</div>
          {fictiveSubstitutes.map((substitute) => (
            <EmployeeItem
              key={substitute.id}
              id={substitute.id}
              name={employees[substitute.id]?.name ?? ''}
              onItemClick={() => onEmployeeSelect(substitute.id)}
              isFictive
              status={substitute.shortRule}
              priority={substitute.priority}
            />
          ))}
        </>
      )}
      {!!unassignableSubstitutes.length && (
        <>
          <div className="list-label">{currentLanguage.OtherEmployees}</div>
          {unassignableSubstitutes.map((substitute) => (
            <EmployeeItem
              key={substitute.id}
              id={substitute.id}
              name={employees[substitute.id]?.name ?? ''}
              status={''}
              priority={substitute.priority}
              onItemClick={() => onEmployeeSelect(substitute.id)}
            />
          ))}
        </>
      )}
    </div>
  );
};
export default FindSubstituteView;

