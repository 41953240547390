import type { Location } from 'history';
import { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { z } from 'zod';
/*
When siteRoutes are defined as a function it means they contain one or more id's that needs to
be given as a parameter for the path to match.
Use the function with empty parameters to get the match string needed for the router.
(Remember therefore to add default values to all id's that needs to be matched as ":id");
*/

/**
 * Values are used in audit logging, so they should not be changed.
 */
export enum RouteEnum {
  Team = 'team',
  Teams = 'teams',
  Login = 'login',
  Chat = 'chat',
}

export type RouteState =
  | {
      route: RouteEnum.Team;
      params: TeamViewQueries;
    }
  | { route: RouteEnum.Teams }
  | { route: RouteEnum.Login }
  | { route: RouteEnum.Chat; params: MessageViewQueries };

export function getRouteState(location: Location): RouteState | null {
  if (location.pathname.startsWith('/teams')) {
    return { route: RouteEnum.Teams };
  }
  if (location.pathname.startsWith('/team')) {
    const params = getTeamRouteParams(location.search);
    return { route: RouteEnum.Team, params };
  }
  if (location.pathname.startsWith('/login')) {
    return { route: RouteEnum.Login };
  }
  if (location.pathname.startsWith('/chat')) {
    const params = getMessageRouteParams(location.search);
    return { route: RouteEnum.Chat, params };
  }
  return null;
}

// These enums have to be string enums, as they will be part of the route urls
export enum Tab {
  Planned = 'planned',
  Pending = 'pending',
  Changes = 'changes',
  Read = 'read',
  Unread = 'unread',
}

export enum TeamViewMode {
  FindSubstitute = 'findsubstitute',
  CreateShift = 'createshift',
}

const teamViewQueriesSchema = z.object({
  personId: z.coerce.number().optional().catch(undefined),
  shiftId: z.string().optional(),
  tab: z.nativeEnum(Tab).optional().catch(undefined),
  mode: z.nativeEnum(TeamViewMode).optional().catch(undefined),
  search: z.string().optional(),
});

export type TeamViewQueries = z.infer<typeof teamViewQueriesSchema>;

const messageViewQueriesSchema = z.object({
  messageId: z.string().optional(),
  tab: z.nativeEnum(Tab).optional().catch(undefined),
});

export type MessageViewQueries = z.infer<typeof messageViewQueriesSchema>;

/**
 * Converts a query object into a query param string
 * @example queriesToString({ tab: 'foo', mode: 'bar' }) => '?tab=foo&mode=bar'
 */
function queriesToString<T extends Record<string, string | number>>(
  queries?: T
) {
  if (queries) {
    const entries: string[] = [];
    for (const [key, value] of Object.entries(queries)) {
      // Ignore empty string
      if (value) {
        entries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
    if (entries.length > 0) {
      return `?${entries.join('&')}`;
    }
  }
  return '';
}

/**
 * Converts a url string into a query object with type validation. Parameter MUST be the search part of a url.
 * Validation is necessary because the url can be changed by the user to any value, and since we might change params, enum values, etc.
 * This is the same reason why all entries are optional.
 * Will never throw, but instead return an empty object if the url is invalid. Error handling is optional.
 */
function parseUrl<T extends z.ZodRawShape, U extends z.ZodObject<T>>(
  schema: U,
  url: string,
  onError?: (
    error: z.ZodError<{
      [k in keyof z.baseObjectInputType<T>]: z.baseObjectInputType<T>[k];
    }>
  ) => void
): Partial<z.infer<U>> {
  const parsed = schema.safeParse(
    Object.fromEntries(new URLSearchParams(url).entries())
  );
  if (parsed.success) {
    return parsed.data as z.infer<U>;
  }
  onError?.(parsed.error);
  return {};
}

/**
 *
 * @param url must be the search part of a url
 */
export function getTeamRouteParams(url: string) {
  return parseUrl(teamViewQueriesSchema, url);
}

export function useTeamRouteParams() {
  const location = useLocation();
  return useMemo(() => getTeamRouteParams(location.search), [location.search]);
}

/**
 * Should only return an empty string if the current page is not the /team page
 * @returns The teamId from the url
 */
export function useTeamId() {
  const match = useMatch(getSiteRoutes().team());
  return match?.params.teamId?.replace(':teamId', '') ?? '';
}

/**
 *
 * @param url must be the search part of a url
 */
export function getMessageRouteParams(url: string) {
  return parseUrl(messageViewQueriesSchema, url);
}

export function useMessageRouteParams() {
  const location = useLocation();
  return useMemo(
    () => getMessageRouteParams(location.search),
    [location.search]
  );
}

const siteRoutes = {
  info: '/login/info',
  login: '/login',
  teams: '/teams',
  chat: (teamId = ':teamId', queries?: MessageViewQueries) =>
    `/chat/${teamId}${queriesToString(queries)}`,
  team: (teamId = ':teamId', queries?: TeamViewQueries) =>
    `/team/${teamId}${queriesToString(queries)}`,
} as const;

export function getSiteRoutes() {
  return siteRoutes;
}

