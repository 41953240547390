import { ActionType } from '../ActionType';
import { CommonAction } from '../commonAction';
import { Reducer } from 'redux';
import { SettingAction } from './SettingsActions';

export interface SettingsReducer {
  language: string;
  allowNotification: boolean;
  errorMessage?: string;
}

// TODO: Can we use international language abbreviations?
const initialState: SettingsReducer = {
  language: 'Da',
  allowNotification: false,
};

const settingsReducer: Reducer<SettingsReducer> = (
  state: SettingsReducer = initialState,
  action: SettingAction | CommonAction
) => {
  switch (action.type) {
    case ActionType.INIT:
      return initialState;
    case 'LANGUAGE_SELECT':
      return {
        ...state,
        language: action.language,
      };
    case 'GETALLOWNOTIFICATIONS_REQUEST':
      return {
        ...state,
      };
    case 'GETALLOWNOTIFICATIONS_SUCCESS':
      return {
        ...state,
        allowNotification: action.allowNotification,
      };
    case 'GETALLOWNOTIFICATIONS_FAILURE':
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case 'SETALLOWNOTIFICATIONS_REQUEST':
      return {
        ...state,
      };
    case 'SETALLOWNOTIFICATIONS_SUCCESS':
      return {
        ...state,
        allowNotification: action.allowNotification,
      };
    case 'SETALLOWNOTIFICATIONS_FAILURE':
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default settingsReducer;
