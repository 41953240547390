import { Language, langMap } from './generatedLanguages';
import {
  capitalize,
  changeDateFormatLanguage,
  objectKeys,
} from '@pdcfrontendui/utils';

let defaultLanguageId = 'Da';
const currentLanguageKey = 'currentLanguage';

export let knownLanguageIds: Record<string, boolean> = {};
objectKeys(langMap).forEach((langKey) => {
  knownLanguageIds[langKey] = true;
});

let browserLanguageCapitalized = capitalize(
  navigator.language || defaultLanguageId
).slice(0, 2);

if (knownLanguageIds[browserLanguageCapitalized]) {
  defaultLanguageId = browserLanguageCapitalized;
}

export let currentLanguage: Language;

// NOTE: notice the currentlanguage does NOT use the TeamPlan localStorage util.
// we use a global currentlanguage without the prepend of teamplan- because of PDC-shell language inheritance
export function setCurrentLanguage(id: string) {
  if (!knownLanguageIds[id]) {
    // Prevent the same error from re-occurring when the application reloads
    localStorage.remove(currentLanguageKey);
    throw new Error('Unknown language: ' + id);
  }
  currentLanguage = langMap[id as keyof typeof langMap];
  changeDateFormatLanguage(currentLanguage);
  try {
    localStorage.setItem(currentLanguageKey, JSON.stringify(id));
  } catch (err) {}
}

export const getCurrentLanguage = (): string => {
  // set lang.
  // try to use the value of currentLanguage from pdcshell if it exists
  try {
    const langRaw = localStorage.getItem(currentLanguageKey);
    const lang: unknown = langRaw ? JSON.parse(langRaw) : null;
    return !lang || typeof lang !== 'string' ? defaultLanguageId : lang;
  } catch (e) {
    return defaultLanguageId;
  }
};

setCurrentLanguage(getCurrentLanguage());
