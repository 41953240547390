import './SwapView.scss';

import * as dateFns from 'date-fns';

import {
  Button,
  ButtonStyle,
  Card,
  Header,
  IconSize,
  Icons,
  LoadingButton,
  MobileSlideIn,
  Modal,
} from '@pdcfrontendui/components';
import { Exchange, Team } from '../api/TeamPlan_api';
import React, { useEffect, useState } from 'react';

import ButtonContainer from '@pdcfrontendui/components/ButtonContainer';
import DayLabel from '../ListView/DayLabel';
import { EmployeeMap } from '../api/model';
import SwapAccordionItem from './SwapAccordionItem';
import SwapRadioAccordionItem from './SwapRadioAccordionItem';
import { currentLanguage } from '../currentLanguage';
import ids from '../testing/ids';
import { useIsDualView } from '@pdcfrontendui/hooks';

type Props = {
  show: boolean;
  swapDetails?: Exchange;
  employees: EmployeeMap;
  team: Team;
  pendingAcceptRequest: boolean;
  pendingRejectRequest: boolean;
  selectedExchangeId?: number;
  acceptRequestEnabled: boolean;
  isSwap: boolean;
  onBackClick: (e?: React.MouseEvent<HTMLDivElement>) => void;
  onAcceptExchange: () => void;
  onRejectExchange: () => void;
  onSelectExchangeId: (exchangeId: number) => void;
  getEmployees: (personIds: number[], teamId: string) => void;
};

const SwapView = ({
  onBackClick,
  onAcceptExchange,
  onRejectExchange,
  onSelectExchangeId,
  getEmployees,
  show,
  swapDetails,
  employees,
  team,
  pendingAcceptRequest,
  pendingRejectRequest,
  selectedExchangeId,
  acceptRequestEnabled,
  isSwap,
}: Props) => {
  const isMobile = !useIsDualView();
  const [offerInfoOpen, setOfferInfoOpen] = useState(false);
  const currentDate = new Date();

  const requestEmployee =
    typeof swapDetails?.requestInfo.personId === 'number'
      ? employees[swapDetails.requestInfo.personId]
      : null;

  useEffect(() => {
    const missingEmployeeInfo: number[] = [];
    if (swapDetails?.swapInfoList && show) {
      swapDetails.swapInfoList.forEach((employee) => {
        if (
          employee.personId &&
          !(
            employees[employee.personId] &&
            missingEmployeeInfo.includes(employee.personId)
          )
        ) {
          missingEmployeeInfo.push(employee.personId);
        }
      });
    }

    if (missingEmployeeInfo.length > 0 && team.id) {
      getEmployees(missingEmployeeInfo, team.id);
    }
  }, [show]);

  return (
    <>
      <MobileSlideIn
        isMobile={isMobile}
        className="SwapView"
        absolute
        bottom={50}
      >
        {show && !!swapDetails && (
          <>
            {!isMobile && <Header></Header>}
            <Header centerize>
              <Header.Left>
                <Icons.ChevronLeft
                  size={IconSize.Medium}
                  onClick={onBackClick}
                />
              </Header.Left>
              <Header.Center>
                <>
                  {!isSwap
                    ? currentLanguage.NumApplicantsFromShiftExchange_1(
                        swapDetails.swapInfoList.length
                      )
                    : currentLanguage.ApproveRejectSwap}
                </>
              </Header.Center>
              <Header.Right>
                <>
                  {!isSwap && (
                    <Icons.Info
                      size={IconSize.XSmall}
                      onClick={() => {
                        setOfferInfoOpen(true);
                      }}
                    />
                  )}
                </>
              </Header.Right>
            </Header>
            <div className="swapview-content">
              <Card className="confirmation-card">
                <div>
                  {!isSwap
                    ? currentLanguage.HandoverApproveRejectText_1(
                        requestEmployee?.name ?? ''
                      )
                    : currentLanguage.SwapApproveRejectText_2(
                        requestEmployee?.name ?? '',
                        swapDetails.swapInfoList[0]?.personId
                          ? employees[swapDetails.swapInfoList[0]?.personId]
                              ?.name ?? ''
                          : ''
                      )}
                </div>
                <div className="confirmation-buttons">
                  <ButtonContainer>
                    <LoadingButton
                      id={ids.SwapView.rejectSwap}
                      variant={ButtonStyle.Secondary}
                      danger
                      onClick={() => {
                        onRejectExchange();
                      }}
                      loading={pendingRejectRequest}
                      disabled={pendingAcceptRequest}
                    >
                      {isSwap
                        ? currentLanguage.Reject
                        : currentLanguage.CancelOffer}
                    </LoadingButton>
                    <LoadingButton
                      id={ids.SwapView.approveSwap}
                      loading={pendingAcceptRequest}
                      disabled={pendingRejectRequest || !acceptRequestEnabled}
                      onClick={() => {
                        onAcceptExchange();
                      }}
                    >
                      {currentLanguage.Approve}
                    </LoadingButton>
                  </ButtonContainer>
                </div>
              </Card>
              <div className="shift-list-container">
                <DayLabel
                  currentDate={currentDate}
                  date={
                    swapDetails.requestInfo.teamShiftList?.[0]?.period.from ??
                    new Date()
                  }
                />
                <SwapAccordionItem
                  id={'requester'}
                  className="swap-accordion-item"
                  employeeName={requestEmployee?.name ?? ''}
                  period={{
                    from:
                      swapDetails.requestInfo.teamShiftList?.[0]?.period.from ??
                      new Date(),
                    to:
                      swapDetails.requestInfo.teamShiftList?.[0]?.period.to ??
                      new Date(),
                  }}
                  dutyLines={
                    swapDetails.requestInfo.teamShiftList?.[0]?.dutyLines
                  }
                  isSwap={isSwap}
                  callIn={swapDetails.requestInfo.callIn}
                  numberOfApplicants={swapDetails.swapInfoList.length}
                  note={swapDetails.requestInfo.plannerRemark}
                  personGroupList={swapDetails.requestInfo.persongroupList}
                  responsibilities={
                    swapDetails.requestInfo.teamShiftList?.[0]?.responsibilities
                  }
                  activities={
                    swapDetails.requestInfo.teamShiftList?.[0]?.activities
                  }
                  deviatingPayerUiLabel={
                    swapDetails.requestInfo.teamShiftList?.[0]
                      ?.deviatingPayerUiLabel ?? null
                  }
                />
                {!isSwap ? (
                  <Icons.ArrowRight
                    size={IconSize.Medium}
                    className="SwapIcon"
                  />
                ) : (
                  <Icons.Swap size={IconSize.Medium} className="SwapIcon" />
                )}

                {!isSwap ? (
                  <div className="applicant-label">
                    {currentLanguage.Applicants}
                  </div>
                ) : (
                  <DayLabel
                    currentDate={currentDate}
                    date={
                      swapDetails.swapInfoList[0]?.teamShiftList[0]?.period
                        .to ?? new Date()
                    }
                  />
                )}
                {isSwap && swapDetails.swapInfoList[0] && (
                  <SwapAccordionItem
                    key={swapDetails.swapInfoList[0].exchangeId}
                    id={`swapper-${dateFns.format(
                      swapDetails.swapInfoList[0].period.from,
                      'YYYYMMDD'
                    )}-${dateFns.format(
                      swapDetails.swapInfoList[0].period.from,
                      'HHmm'
                    )}-${swapDetails.swapInfoList[0].personId}`}
                    className="swap-accordion-item"
                    employeeName={
                      employees[swapDetails.swapInfoList[0]?.personId ?? '']
                        ?.name ?? ''
                    }
                    period={{
                      from:
                        swapDetails.swapInfoList[0].teamShiftList?.[0]?.period
                          .from ?? new Date(),
                      to:
                        swapDetails.swapInfoList[0].teamShiftList?.[0]?.period
                          .to ?? new Date(),
                    }}
                    dutyLines={
                      swapDetails.swapInfoList[0].teamShiftList?.[0]?.dutyLines
                    }
                    isSwap={true}
                    callIn={swapDetails.swapInfoList[0].callIn}
                    numberOfApplicants={swapDetails.swapInfoList.length}
                    note={swapDetails.swapInfoList[0].remark ?? ''}
                    personGroupList={
                      swapDetails.swapInfoList[0].persongroupList ?? null
                    }
                    responsibilities={
                      swapDetails.swapInfoList[0].teamShiftList?.[0]
                        ?.responsibilities
                    }
                    activities={
                      swapDetails.swapInfoList[0].teamShiftList?.[0]?.activities
                    }
                    deviatingPayerUiLabel={
                      swapDetails.swapInfoList[0].teamShiftList?.[0]
                        ?.deviatingPayerUiLabel ?? null
                    }
                  />
                )}
                {!isSwap && (
                  <div className="swap-list">
                    {swapDetails.swapInfoList
                      .sort(
                        (a, b) =>
                          Number(a.callIn?.priority) -
                          Number(b.callIn?.priority)
                      )
                      .map((swap) => {
                        const employee = swap.personId
                          ? employees[swap.personId]
                          : null;

                        return (
                          <SwapRadioAccordionItem
                            key={swap.exchangeId}
                            id={`swapper-${dateFns.format(
                              swap.period.from,
                              'YYYYMMDD'
                            )}-${dateFns.format(swap.period.from, 'HHmm')}-${
                              swap.personId
                            }`}
                            employeeName={employee?.name ?? ''}
                            dutyLines={swap.teamShiftList?.[0]?.dutyLines}
                            team={team}
                            isSwap={false}
                            callIn={swap.callIn}
                            selected={swap.exchangeId === selectedExchangeId}
                            onSelect={() => onSelectExchangeId(swap.exchangeId)}
                            note={swap.remark}
                            personGroupList={swap.persongroupList}
                            deviatingPayerUiLabel={
                              swap.teamShiftList?.[0]?.deviatingPayerUiLabel ??
                              null
                            }
                          />
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </MobileSlideIn>
      <Modal shown={show && !isSwap && offerInfoOpen}>
        <Modal.Header>{currentLanguage.RuleBreak}</Modal.Header>
        <Modal.Body className="rule-violation-info ">
          {currentLanguage.RuleViolationInfoApplicants}
        </Modal.Body>
        <Modal.Buttons>
          <Button
            onClick={() => {
              setOfferInfoOpen(false);
            }}
          >
            {currentLanguage.Close}
          </Button>
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default SwapView;
