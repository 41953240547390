import type { Location } from 'history';
import { NavigateFunction } from 'react-router-dom';

export const history: {
  location: Location;
  navigate: NavigateFunction;
} = {
  location: { hash: '', pathname: '', search: '', state: null },
  navigate: () => undefined,
};

export function push(url: string) {
  history.navigate(url);
}

export function replace(url: string) {
  history.navigate(url, { replace: true });
}

export function goBack() {
  history.navigate(-1);
}
