import { Prettify } from '@pdcfrontendui/types';
import { TeamDuty, TeamShift, TeamShiftDef } from '../../api/TeamPlan_api';
import { TeamShiftStatusEnum } from '../../api/enumLib_api';
import { EDITED_SHIFT_ID } from '../../constants';

export type EditedShift = {
  /**
   * The original id of the shift that is being edited.
   * Corresponds to {@link TeamShift}.id.
   * If originalId is null, a new shift is being created.
   */
  originalId: string | null;
  from: Date;
  to: Date;
  /**
   * The shift definition of the shift. Will be null if originalId is not null or no type has been selected yet.
   */
  def: TeamShiftDef | null;
  /**
   * The duty lines of the shift. Will be identical to def.dutyLines if the shift is not edited.
   */
  dutyLines: TeamDuty[];
  /** Whether or not it should be treated as an empty default (causes from and to field to be empty initially) */
  isDefault?: boolean;
};

/**
 * Derived type for {@link EditedShift} where def is not null.
 */
export type CreatedShift = Prettify<
  EditedShift & { def: NonNullable<unknown>; originalId: null }
>;

export function isCreatedShift(shift: EditedShift): shift is CreatedShift {
  return shift.originalId === null && shift.def !== null;
}

/**
 * Convert the current edited shift to a TeamShift, so that it can fit into the same flows as an ordinary shift.
 */
export function editedShiftToTeamShift(editedShift: EditedShift): TeamShift {
  return {
    id: editedShift.originalId ?? EDITED_SHIFT_ID,
    type: '',
    from: editedShift.from,
    to: editedShift.to,
    formerPersonId: 0,
    label: editedShift.def?.label ?? '',
    personId: -999, // Arbitrary value, meant to not match any person
    numSwapSuggestions: null,
    period: { from: editedShift.from, to: editedShift.to },
    status: TeamShiftStatusEnum.actionRequired,
    record: [],
    responsibilities: [],
    activities: [],
    dutyLines: editedShift.dutyLines,
    dutyLinesOriginal: editedShift.dutyLines,
    tasks: [],
    deviatingPayerUiLabel: null,
  };
}
