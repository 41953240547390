import { useCallback, useEffect } from 'react';
import { currentLanguage } from '../currentLanguage';
import { BlockerFunction, useBlocker } from 'react-router-dom';
import { ConfirmDialogProps } from './ConfirmDialog/ConfirmDialog';
import { connect } from '../rootReducer';
import { showConfirmDialog } from '../appActions';
import { RouteEnum, TeamViewMode, getRouteState } from '../routes';
import { EDITED_SHIFT_ID } from '../constants';
import { EditedShift } from '../ListView/CreateShift/EditedShift';
import { setEditedShift } from '../ShiftView/ShiftViewActions';
import { ModalVariant } from '@pdcfrontendui/components';

/**
 * A router may only have one blocker at a time.
 * Therefore this component works as a single blocker for all confirmations.
 */
export const RouteBlocker = connect<
  { editedShift: EditedShift | null },
  {
    showDialog: (modal: Omit<ConfirmDialogProps, 'shown'>) => void;
    removeEditedShift: () => void;
  }
>(
  (store) => ({ editedShift: store.shiftViewReducer.editedShift }),
  (dispatch) => ({
    showDialog: (modal) => {
      dispatch(showConfirmDialog(modal));
    },
    removeEditedShift: () => {
      dispatch(setEditedShift(null));
    },
  })
)(({ showDialog, editedShift, removeEditedShift }) => {
  const shouldBlock: BlockerFunction = useCallback(
    ({ currentLocation, nextLocation }) => {
      if (!editedShift) {
        return false;
      }
      const curr = getRouteState(currentLocation);
      const next = getRouteState(nextLocation);
      if (curr?.route === RouteEnum.Team) {
        // Logging out already has a confirmation dialog
        if (next?.route === RouteEnum.Login) {
          return false;
        }
        // Prevent redirecting while create shift is open (to other views than find substitute)
        if (
          curr.params.mode === TeamViewMode.CreateShift &&
          (next?.route !== RouteEnum.Team ||
            next.params.mode !== TeamViewMode.FindSubstitute)
        ) {
          return true;
        }
        if (
          curr.params.shiftId === EDITED_SHIFT_ID &&
          curr.params.mode === TeamViewMode.FindSubstitute &&
          (next?.route !== RouteEnum.Team ||
            (next.params.mode !== TeamViewMode.FindSubstitute &&
              next.params.mode !== TeamViewMode.CreateShift))
        ) {
          return true;
        }
      }
      return false;
    },
    [editedShift]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (editedShift) {
        showDialog({
          title: currentLanguage.ConfirmLeavePage,
          message: currentLanguage.ConfirmLeavePageContent,
          buttonCancelText: currentLanguage.Cancel,
          buttonOkText: currentLanguage.YesLeavePage,
          onCancel: () => blocker.reset(),
          onOk: removeEditedShift,
          variant: ModalVariant.Alert,
        });
      } else {
        blocker.proceed();
      }
    }
  }, [blocker, showDialog, removeEditedShift, editedShift]);

  return null;
});
