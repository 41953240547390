import { AppAction, setToken, setTokenTimestamp } from '../appActions';
import { LoginAction, loginSuccess } from '../Login/LoginAction';
import { SettingAction, languageSelect } from '../Settings/SettingsActions';
import { getCurrentLanguage, setCurrentLanguage } from '../currentLanguage';
import {
  localStorageGet,
  localStorageRemove,
  localStorageSet,
} from '../util/localStorage';

import { CommonAction } from '../commonAction';
import { ListViewAction } from '../ListView/ListViewActions';
import { LocalStorageKey } from '../util/LocalStorageKey';
import { Middleware } from 'redux';
import { enablePushNotifications } from '../api/PushNotification';

export const persistAndInit: Middleware = (_store) => (next) => {
  return (
    action:
      | AppAction
      | LoginAction
      | SettingAction
      | CommonAction
      | ListViewAction
  ) => {
    switch (action.type) {
      // find out what things needs to be loaded from localStorage
      case 'RESUME_SESSION':
        // eslint-disable-next-line
        let token = localStorageGet(LocalStorageKey.token);
        if (token) {
          // TODO enablePushNotifications is also called in the loginSuccessHandler function
          enablePushNotifications();
          next(setToken(token));

          const userId = localStorageGet(LocalStorageKey.userId);
          const userType = localStorageGet(LocalStorageKey.userType);
          const fullName = localStorageGet(LocalStorageKey.fullName);
          if (
            typeof userId === 'number' &&
            typeof userType === 'number' &&
            typeof fullName === 'string'
          ) {
            next(loginSuccess(token, userType, userId, fullName));
          }
        }
        // set init lang
        next(languageSelect(getCurrentLanguage()));
        setCurrentLanguage(getCurrentLanguage());
        break;
      // Persist token on login and periodically when it changes
      case 'SET_TOKEN':
        localStorageSet(LocalStorageKey.token, action.token);
        break;
      case 'SET_TOKEN_TIMESTAMP':
        localStorageSet(LocalStorageKey.tokenTimestamp, action.tokenTimestamp);
        break;
      case 'LOGIN_SUCCESS':
        localStorageSet(LocalStorageKey.userId, action.userId);
        localStorageSet(LocalStorageKey.userType, action.userType);
        localStorageSet(LocalStorageKey.fullName, action.fullName);
        break;
      case 'LOGOUT':
        // Removal of token is handled in fetcher
        localStorageRemove(LocalStorageKey.userId);
        localStorageRemove(LocalStorageKey.userType);
        localStorageRemove(LocalStorageKey.fullName);
        break;
      // Persist language select
      case 'LANGUAGE_SELECT':
        setCurrentLanguage(action.language); // make sure to also setCurrentLanguage
        break;
      case 'CHANGE_TEAM':
        localStorageSet(LocalStorageKey.currentTeam, action.teamId);
        break;
    }
    return next(action);
  };
};
