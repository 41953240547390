import 'isomorphic-fetch';

import {
  ApiError,
  HttpError,
  ValidationError,
  getAuditActionData,
  makeFetcher,
} from '@pdcfrontendui/staffplan';
import { localStorageGet, localStorageRemove } from '../util/localStorage';
import { setToken, setTokenTimestamp, showErrorModal } from '../appActions';

import { LocalStorageKey } from '../util/LocalStorageKey';
import { currentLanguage } from '../currentLanguage';
import { logout } from '../Login/LoginAction';
import { store } from '../storeConfiguration';

const onError = (error: ApiError | HttpError | ValidationError) => {
  if (!error.url?.match(/Log2/i)) {
    const httpCode =
      error instanceof ApiError
        ? error.status
        : error instanceof HttpError
        ? error.httpStatus
        : 0;
    if (httpCode === 401) {
      store.dispatch(logout(false));
      return;
    } else if (httpCode === 500) {
      // The load balancer transforms all http error codes into 500, so this could
      // really be a 401 (unauthorized) because the jwt has expired.
      // If the jwt is older than 30 minutes (default jwt lifetime),
      // it's assumed that the jwt is the cause of the error, and the user is logged out.
      const tokenTimestamp = localStorageGet(LocalStorageKey.tokenTimestamp);
      if (tokenTimestamp) {
        const now = new Date().getTime();
        if (now - tokenTimestamp > 30 * 60 * 1000) {
          store.dispatch(logout(false));
          return;
        }
      }
    }
    store.dispatch(showErrorModal(error));
  }
};

export const fetcher = makeFetcher({
  getToken: () => {
    const token = localStorageGet(LocalStorageKey.token);
    if (!token) {
      throw new ApiError('No token found');
    }
    return token;
  },
  setToken: (token) => {
    store.dispatch(setToken(token));
    store.dispatch(setTokenTimestamp(new Date().getTime()));
  },
  removeToken: () => localStorageRemove(LocalStorageKey.token),
  onAuthError: () => void store.dispatch(logout()),
  onApiError: onError,
  onValidationError: onError,
  onHttpError: onError,
  getAuditActionData,
  getLangCode: () => currentLanguage.languageCode,
  applicationId: 201,
});
