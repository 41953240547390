// This file defines the api that TeamPlan has towards the server.  It ties
// together the model objects as defined in `generatedApi.ts`, model objects
// from `model.ts`, `fetcher.ts`.
//

import * as api from './TeamPlan_api';
import * as calendarApi from './Calendar_api';
import * as loginApi from './Login_api';
import * as messageApi from './Message_api';
import * as commonApi from './Common_api';

import {
  ApplicationEnum,
  ExchangeCandidateFilterEnum,
  ExchangeTypeEnum,
  FavoriteStatusEnum,
  MsgVisibilityOldEnum,
  NotificationStatusEnum,
  PushProviderTypeEnum,
} from './enumLib_api';
import {
  GetAllowNotifications,
  RegisterDevice,
  SetAllowNotifications,
} from './PushNotification_api';
import {
  NotificationLogObj,
  getCallInData,
  getTeamRoster,
  toIdMap,
} from './model';
import { ReportTracking, TrackingObject } from './Tracking_api';

import { CreatedShift } from '../ListView/CreateShift/EditedShift';
import { FetcherOptions } from '@pdcfrontendui/staffplan/api/makeFetcher';
import { Log2 } from './NotificationLog_api';
import { Period } from '../util/dates';
import { WebRequest } from './WebRequest';
import { dateMath } from '@pdcfrontendui/utils';
import { fetcher } from './fetcher';
import { hashPassword } from '../util/passwordHandling';
import { periodToMinuteOffsetInterval } from '../util/minuteOffset';

export class Api {
  constructor(public baseUrl: string, private storedViewName: string) {
    if (!baseUrl) {
      throw new Error('Need baseUrl');
    }
    if (!storedViewName) {
      throw new Error('Need storedViewName');
    }
  }

  fetch<T>(obj: WebRequest<T>, options?: Partial<FetcherOptions>) {
    return fetcher(`${this.baseUrl}/${obj.webService}`, obj, options);
  }

  login(username: string, password: string) {
    const passwordHash = hashPassword(username, password);
    const obj = new loginApi.Login_02(
      username,
      passwordHash,
      ApplicationEnum.teamPlan
    );
    return this.fetch(obj);
  }

  loginOpenId(accessCode: string, redirectUri: string) {
    const obj = new loginApi.LoginOpenId_planner(accessCode, redirectUri);
    return this.fetch(obj);
  }

  logout() {
    const obj = new loginApi.Logout();
    return this.fetch(obj, {
      removeTokenOnRequest: true,
    });
  }

  getVersion(): Promise<api.SystemInformation> {
    const obj = new api.GetVersion();
    return this.fetch(obj);
  }
  getGlobalSettings(): Promise<commonApi.GlobalSettings> {
    const obj = new commonApi.GetGlobalSettings();
    return this.fetch(obj);
  }

  getUserSettings(): Promise<api.UserSettings> {
    const obj = new api.GetUserSettings();
    return this.fetch(obj);
  }

  getHolidays(): Promise<calendarApi.HolidayResponse> {
    const obj = new calendarApi.GetHolidays();
    return this.fetch(obj);
  }

  getTeams() {
    const obj = new api.GetTeams();
    return this.fetch(obj).then(toIdMap);
  }

  setFavoriteStatus(teamId: string, status: FavoriteStatusEnum) {
    const obj = new api.SetFavoriteStatus(teamId, status);
    return this.fetch(obj);
  }

  setNotificationStatus(teamId: string, status: NotificationStatusEnum) {
    const obj = new api.SetNotificationStatus(teamId, status);
    return this.fetch(obj);
  }

  getEmployees(personIds: number[], teamId: string) {
    const obj = new api.GetEmployees(personIds, teamId);
    return this.fetch(obj).then(toIdMap);
  }

  getTeamRoster(teamId: string, period: Period, isPoll: boolean) {
    const obj = new api.GetTeamRoster(teamId, period, this.storedViewName);
    return this.fetch(obj, { isPoll }).then((data) => {
      return getTeamRoster(data);
    });
  }

  offerOnShiftExchange(
    shiftId: string,
    teamIdList: string[],
    candiateFilter: ExchangeCandidateFilterEnum
  ) {
    const obj = new api.OfferOnShiftExchange(
      shiftId,
      teamIdList,
      this.storedViewName,
      candiateFilter
    );
    return this.fetch(obj);
  }

  cancelOfferOnShiftExchange(shiftId: string, teamId: string) {
    const obj = new api.CancelOfferOnShiftExchange(
      shiftId,
      teamId,
      this.storedViewName
    );
    return this.fetch(obj);
  }

  getAbsenceTypes(shiftId: string, teamId: string) {
    const obj = new api.GetAbsenceTypes(shiftId, teamId);
    return this.fetch(obj);
  }

  registerAbsence(
    shiftId: string,
    teamId: string,
    absenceTypeId: string,
    returnDate: Date | null
  ) {
    const obj = new api.RegisterAbsence(
      shiftId,
      teamId,
      absenceTypeId,
      this.storedViewName,
      returnDate
    );
    return this.fetch(obj);
  }

  markAsHandled(shiftId: string, teamId: string) {
    const obj = new api.MarkAsHandled(shiftId, teamId, this.storedViewName);
    return this.fetch(obj);
  }

  getCallIn(shiftId: string, teamId: string) {
    const obj = new api.GetCallInList(shiftId, teamId);
    return this.fetch(obj).then((data) => {
      return getCallInData(data);
    });
  }

  assignShift(teamId: string, personId: number, shiftId: string) {
    const obj = new api.AssignCallInList(
      shiftId,
      teamId,
      [personId],
      this.storedViewName
    );
    return this.fetch(obj);
  }

  sendNotificationLog(notificationLogObj: NotificationLogObj) {
    const obj = new Log2(
      notificationLogObj.groupOn,
      notificationLogObj.exceptionName,
      notificationLogObj.exceptionMsg,
      notificationLogObj.userComment || '',
      notificationLogObj.clientInfo
    );
    return this.fetch(obj);
  }

  reportTracking(trackingObject: TrackingObject) {
    const obj = new ReportTracking(trackingObject);
    return this.fetch(obj);
  }

  getAllowNotifications() {
    const obj = new GetAllowNotifications();
    return this.fetch(obj);
  }

  setAllowNotifications(allowNotification: boolean) {
    const obj = new SetAllowNotifications(allowNotification);
    return this.fetch(obj);
  }

  registerDevice(deviceToken: string, serviceType: PushProviderTypeEnum) {
    const obj = new RegisterDevice(deviceToken, serviceType);
    return this.fetch(obj);
  }

  async getMessages(
    personIds: number[],
    from: Date,
    to: Date,
    isPoll: boolean
  ) {
    const obj = new messageApi.GetMessages(personIds, {
      from: dateMath.floorDay(from),
      to: dateMath.floorDay(to),
    });
    // Only show error once pr. session as this is called periodically
    return await this.fetch(obj, {
      maxOnApiErrorCalls: 1,
      isPoll,
    });
  }

  async postMessage(
    currentDate: Date,
    personId: number,
    messageText: string,
    isInternalMessage: boolean
  ) {
    const obj = new messageApi.PostMessage(
      personId,
      currentDate,
      messageText,
      isInternalMessage
        ? MsgVisibilityOldEnum.planner
        : MsgVisibilityOldEnum.employee
    );
    return await this.fetch(obj);
  }

  async markMessagesAsRead(messageIds: number[]) {
    const obj = new messageApi.MarkMessagesAsRead(messageIds);
    // Only show error once pr. session as this is called on component mounts
    return await this.fetch(obj, { maxOnApiErrorCalls: 1 });
  }

  async getUnreadMessagesCount(
    teamIds: string[],
    from: Date,
    to: Date,
    isPoll: boolean
  ) {
    const obj = new messageApi.GetUnreadCounts(teamIds, {
      from: dateMath.floorDay(from),
      to: dateMath.floorDay(to),
    });
    return await this.fetch(obj, {
      isPoll,
      maxOnApiErrorCalls: 1,
    });
  }

  getExchange(personId: number, shiftStart: Date) {
    const obj = new api.GetExchange(personId, shiftStart);
    return this.fetch(obj);
  }

  exchangeAccept(
    unitidId: string,
    requestExchangePeriod: Period,
    offerPersonId: number,
    offerExchangeId: number,
    requestPersonId: number | null
  ) {
    const obj = new api.AcceptExchange(
      unitidId,
      requestExchangePeriod,
      offerPersonId,
      offerExchangeId,
      requestPersonId
    );
    return this.fetch(obj);
  }

  exchangeReject(
    exchangeId: number,
    exchangePeriod: Period,
    exchangeUnitId: string,
    exchangeType: ExchangeTypeEnum,
    remark: string | null
  ) {
    const obj = new api.CancelExchange(
      exchangeId,
      exchangePeriod,
      exchangeUnitId,
      exchangeType,
      remark
    );
    return this.fetch(obj);
  }

  async getShiftDefs(teamId: string, period: Period) {
    const obj = new api.GetShiftDefs(teamId, period);
    return await this.fetch(obj, { maxOnApiErrorCalls: 1 });
  }

  async getModifiedDutyLines(shift: CreatedShift) {
    const interval = periodToMinuteOffsetInterval(shift);
    const obj = new api.GetModifiedDutyLines(
      shift.def.unitid,
      shift.from,
      shift.def.id,
      interval.from,
      interval.to
    );
    return await this.fetch(obj);
  }

  async getShiftCandidateList(teamId: string, shift: CreatedShift) {
    const interval = periodToMinuteOffsetInterval(shift);
    const obj = new api.GetShiftCandidateList(
      teamId,
      shift.from,
      shift.def.id,
      interval.from,
      interval.to
    );
    return await this.fetch(obj);
  }

  async assignShiftCandidate(
    teamId: string,
    personId: number,
    shift: CreatedShift
  ) {
    const interval = periodToMinuteOffsetInterval(shift);
    const obj = new api.AssignShiftCandidate(
      teamId,
      shift.from,
      personId,
      this.storedViewName,
      shift.def.id,
      interval.from,
      interval.to
    );
    return await this.fetch(obj);
  }

  removeShift(shiftId: string, teamId: string) {
    const obj = new api.RemoveShift(shiftId, teamId);
    return this.fetch(obj);
  }
}
