import * as dateFns from 'date-fns';

import { DayMessageMap, getChatKey } from './getChatKey';
import { DayShiftMap, EmployeeMap, ShiftMap } from '../api/model';
import { MsgVisibilityOldEnum, TeamShiftStatusEnum } from '../api/enumLib_api';
import { dateMath, dayKey, searchString } from '@pdcfrontendui/utils';

import { Period } from './dates';
import { Tab } from '../routes';
import { TeamShift } from '../api/TeamPlan_api';
import { currentLanguage } from '../currentLanguage';
import { shouldShowAsUnread } from '../Chat/chatUtils';

export function getSearchFilteredShifts(
  dayMap: Record<string, TeamShift[]>,
  employees: EmployeeMap,
  search: string
) {
  return Object.entries(dayMap).reduce<Record<string, TeamShift[]>>(
    (acc, [key, shifts]) => {
      acc[key] = shifts.filter((shift) => {
        const employee = employees[shift.personId];
        return !!employee && searchString(search, employee.name);
      });
      return acc;
    },
    {}
  );
}

function shiftSort(a: TeamShift, b: TeamShift) {
  if (dateFns.isEqual(a.period.from, b.period.from)) {
    if (dateFns.isAfter(a.period.to, b.period.to)) {
      // if the dates are the same, sort the items by the end time
      return 1;
    } else {
      return -1;
    }
  } else if (dateFns.isAfter(a.period.from, b.period.from)) {
    // if date a is after b, make a come after b
    return 1;
  } else {
    // else, a must be before b, make a come before b
    return -1;
  }
}

function dayKeysToMap(keys: string[]) {
  return keys.reduce<Record<string, TeamShift[]>>((acc, key) => {
    acc[key] = [];
    return acc;
  }, {});
}

/**
 *
 * Get an object where keys are the days of the period, and values are arrays of shifts to be rendered in the list view
 * Also returns a lookup of which shifts have messages and which have unread messages, for fast lookup while rendering
 */
export function getDayShiftsMap(
  period: Period,
  shifts: ShiftMap,
  dayMessageMap: DayMessageMap,
  userId: number
) {
  const dayKeys = dateFns.eachDay(period.from, period.to).map(dayKey);
  const dayShiftsMap: DayShiftMap = {
    planned: dayKeysToMap(dayKeys),
    pending: dayKeysToMap(dayKeys),
    changes: dayKeysToMap(dayKeys),
    hasMessageLookup: {},
    hasUnreadLookup: {},
  };
  const shiftArr = Object.values(shifts).sort(shiftSort);
  for (const shift of shiftArr) {
    const currentDate = dateMath.floorDay(shift.period.from);
    while (currentDate < shift.period.to) {
      const currentKey = dayKey(currentDate);
      switch (shift.status) {
        // Special case for substitute, they are both planned and changes
        // Means that there has been a swap, so we want to see it in both tabs
        case TeamShiftStatusEnum.substitute:
          dayShiftsMap.planned[currentKey]?.push(shift);
          dayShiftsMap.changes[currentKey]?.push(shift);
          break;
        // Exchange items are both planned and pending changes
        case TeamShiftStatusEnum.offerActionRequired:
        case TeamShiftStatusEnum.swapActionRequired:
          dayShiftsMap.planned[currentKey]?.push(shift);
          dayShiftsMap.pending[currentKey]?.push(shift);
          break;
        case TeamShiftStatusEnum.planned:
        case TeamShiftStatusEnum.noActivities:
          dayShiftsMap.planned[currentKey]?.push(shift);
          break;
        // Everything that requires immediate action
        case TeamShiftStatusEnum.offered:
        case TeamShiftStatusEnum.actionRequired:
          dayShiftsMap.pending[currentKey]?.push(shift);
          break;
        case TeamShiftStatusEnum.replaced:
        case TeamShiftStatusEnum.absent: // absent means that no one is assigned to the shift, and that we don't want to find a replacement
          dayShiftsMap.changes[currentKey]?.push(shift);
          break;
      }
      const chatKeyPrivate = getChatKey(
        shift.personId,
        currentDate,
        MsgVisibilityOldEnum.planner
      );
      const chatKeyPublic = getChatKey(
        shift.personId,
        currentDate,
        MsgVisibilityOldEnum.employee
      );
      const internalMessages = dayMessageMap[chatKeyPrivate] ?? null;
      const messages = dayMessageMap[chatKeyPublic] ?? null;
      const hasInternalMessage = !!internalMessages?.length;
      if (hasInternalMessage) {
        dayShiftsMap.hasMessageLookup[shift.id] = true;
      }
      const hasMessage = !!messages?.length;
      if (hasMessage) {
        dayShiftsMap.hasMessageLookup[shift.id] = true;
      }
      const hasUnreadMessage = !!messages?.some((message) =>
        shouldShowAsUnread(message, userId)
      );
      const hasUnreadInternalMessage = !!internalMessages?.some((message) =>
        shouldShowAsUnread(message, userId)
      );
      if (hasUnreadMessage || hasUnreadInternalMessage) {
        dayShiftsMap.hasUnreadLookup[shift.id] = true;
      }
      currentDate.setTime(currentDate.getTime() + 24 * 60 * 60 * 1000);
    }
  }
  return dayShiftsMap;
}

export function getShiftStatusText(
  status: TeamShiftStatusEnum,
  selectedTab?: Tab
): string {
  switch (status) {
    case TeamShiftStatusEnum.planned:
      return currentLanguage.ShiftStatusPlanned;
    case TeamShiftStatusEnum.actionRequired:
      return currentLanguage.ShiftStatusActionRequired;
    case TeamShiftStatusEnum.offered:
      return currentLanguage.ShiftStatusOffered;
    case TeamShiftStatusEnum.replaced:
      return currentLanguage.ShiftStatusReplaced;
    case TeamShiftStatusEnum.absent:
      return currentLanguage.ShiftStatusAbsent;
    case TeamShiftStatusEnum.substitute:
      return currentLanguage.ShiftStatusSubstitute;
    case TeamShiftStatusEnum.noActivities:
      return currentLanguage.ShiftStatusNoActivities;
    case TeamShiftStatusEnum.offerActionRequired:
      if (!selectedTab || selectedTab === Tab.Planned) {
        return currentLanguage.AwaitingApproveHandover;
      } else {
        return currentLanguage.ApproveRejectHandover;
      }
    case TeamShiftStatusEnum.swapActionRequired:
      if (!selectedTab || selectedTab === Tab.Planned) {
        return currentLanguage.AwaitingApproveSwap;
      } else {
        return currentLanguage.ApproveRejectSwap;
      }
    default:
      return '';
  }
}

export const getShiftStatusStyling = (status: TeamShiftStatusEnum) => {
  switch (status) {
    case TeamShiftStatusEnum.actionRequired:
    case TeamShiftStatusEnum.offered:
    case TeamShiftStatusEnum.offerActionRequired:
    case TeamShiftStatusEnum.swapActionRequired:
      return 'alert';
    case TeamShiftStatusEnum.substitute:
      return 'secondary';
    case TeamShiftStatusEnum.absent:
    case TeamShiftStatusEnum.replaced:
      return 'grey';
    case TeamShiftStatusEnum.planned:
    case TeamShiftStatusEnum.noActivities:
    default:
      return 'primary';
  }
};
