import {
  AbsenceType,
  CallIn,
  Team,
  TeamDuty,
  TeamShift,
} from '../api/TeamPlan_api';
import {
  Button,
  ButtonIconPosition,
  ButtonStyle,
  IconSize,
  Icons,
  LoadingButton,
} from '@pdcfrontendui/components';
import { getShiftStatusStyling, getShiftStatusText } from '../util/shifts';

import { Duties } from './Duties';
import { EmployeeMap } from '../api/model';
import { Item } from '../components';
import React from 'react';
import RegisterAbsenceSection from './Sections/RegisterAbsenceSection';
import { TeamShiftStatusEnum } from '../api/enumLib_api';
import { WebRecordType } from '../api/Common_api';
import classNames from 'classnames';
import colorVarMap from '@pdcfrontendui/components/colors/colors';
import { currentLanguage } from '../currentLanguage';
import dateFns from 'date-fns';
import { formatActivityOrDutylineTime } from '../util/dates';
import ids from '../testing/ids';

export function ShiftInfo({
  shift,
  shiftIsAssignable,
  absenceTypes,
  dutyLines,
  shiftRecordType,
  employees,
  children,
  substitute,
  onRemoveShift,
  isCreatedShift,
}: {
  shift: TeamShift;
  shiftIsAssignable?: boolean;
  absenceTypes: AbsenceType[];
  dutyLines: TeamDuty[];
  shiftRecordType: WebRecordType;
  employees: EmployeeMap;
  children?: React.ReactNode;
  substitute?: CallIn;
  onRemoveShift?: () => void;
  isCreatedShift: boolean;
}) {
  const hideAbsenceType =
    shift.status === TeamShiftStatusEnum.planned ||
    shift.status === TeamShiftStatusEnum.substitute ||
    shift.status === TeamShiftStatusEnum.noActivities ||
    shift.status === TeamShiftStatusEnum.swapActionRequired ||
    shiftIsAssignable;

  const formerAssignee = employees[shift.formerPersonId];
  return (
    <div
      className={classNames('shift-information-section', {
        ['itemToAssign']: shiftIsAssignable,
      })}
    >
      {shiftIsAssignable && !isCreatedShift && (
        <div className="originalEmployee">
          {`${currentLanguage.OriginalBelongingTo} ${
            employees[shift.personId]?.name ?? ''
          }`}
        </div>
      )}
      <h3 className="shift-information-title">
        <div className="shift-information-label">
          <div>{shift.label}</div>
          {shift.responsibilities.length > 0 && (
            <div>
              {shift.responsibilities.map((val, i) => (
                <div key={i}>{val}</div>
              ))}
            </div>
          )}
        </div>
        {!isCreatedShift && onRemoveShift && !shiftIsAssignable && (
          <div>
            <Icons.Delete
              color={colorVarMap.colorErrorDark}
              onClick={() => {
                onRemoveShift();
              }}
            />
          </div>
        )}
      </h3>
      <div
        className={classNames('info-table', {
          ['itemToAssign']: shiftIsAssignable,
        })}
      >
        {!hideAbsenceType && (
          <div>
            <div>{currentLanguage.absenceType}</div>
            <div>{absenceTypes.find((e) => e.id === shift.type)?.label}</div>
          </div>
        )}
        {!shiftIsAssignable && (
          <div
            className={classNames(
              'shift-status',
              getShiftStatusStyling(shift.status)
            )}
          >
            <div>{currentLanguage.Status}</div>
            <div>
              {shift.status === TeamShiftStatusEnum.offerActionRequired
                ? currentLanguage.NumApplicantsFromShiftExchange_1(
                    shift.numSwapSuggestions ?? 0
                  )
                : getShiftStatusText(shift.status)}
            </div>
          </div>
        )}

        {shift.deviatingPayerUiLabel && (
          <div>
            <div>{currentLanguage.Location}</div>
            <div className="deviating-payer">
              <Icons.Info size={IconSize.XXXSmall} />
              <span>{shift.deviatingPayerUiLabel}</span>
            </div>
          </div>
        )}

        {formerAssignee && (
          <div>
            <div>{currentLanguage.FormerAssignee}</div>
            <div>{formerAssignee.name}</div>
          </div>
        )}
        {!!substitute && substitute.descCause && (
          <div>
            <div>{currentLanguage.RuleBreak}</div>
            <div className="rule-break-priority-status">
              {substitute.priority && (
                <div>{Math.floor(substitute.priority)}:&nbsp;</div>
              )}
              <div>{substitute.descCause}</div>
            </div>
          </div>
        )}
        {shift.activities.length > 0 && (
          <div>
            <div>{currentLanguage.Activities}</div>
            <div>
              {shift.activities.map((activity, index) => (
                <div key={index}>
                  <div>{formatActivityOrDutylineTime(activity)}</div>
                  <div>{activity.label}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {shift.tasks.length > 0 && (
          <div>
            <div>{currentLanguage.Tasks}</div>
            <div>
              {shift.tasks.map((task, index) => (
                <div key={index}>
                  <div>{formatActivityOrDutylineTime(task)}</div>
                  <div>{task.label}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {dutyLines.length > 0 && (
          <div>
            <Duties dutyLines={dutyLines} />
          </div>
        )}
        {shiftRecordType.fieldList.map((type, index) => {
          const rec = shift.record.filter((r) => r.name === type.fieldName)[0];
          if (rec?.value && rec.value.length > 0) {
            return (
              <div key={index}>
                <div>{type.label}</div>
                <div>{rec.value}</div>
              </div>
            );
          }
          return null;
        })}
      </div>
      {children}
    </div>
  );
}

export default function ShiftCard({
  team,
  shift,
  personId,
  employees,
  absenceTypes,
  shiftRecordType,
  currentDate,
  loading,
  shiftIsAssignable,
  canCancelOffer,
  callFindSubstitute,
  callAssignShift,
  callMarkAsHandled,
  callRegisterAbsence,
  callCancelOfferOnShiftExchange,
  onRemoveShift,
  setSwapViewOpen,
  setOfferViewOpen,
  loadingSwap,
  substitute,
  isSwap,
  swappable,
  isCreatedShift,
}: {
  employees: EmployeeMap;
  team: Team;
  shift: TeamShift;
  personId: number;
  shiftIsAssignable: boolean;
  currentDate: Date;
  absenceTypes: AbsenceType[];
  shiftRecordType: WebRecordType;
  loading: boolean;
  loadingSwap: boolean;
  substitute?: CallIn;
  isSwap: boolean;
  swappable: boolean;
  canCancelOffer: boolean;
  isCreatedShift: boolean;
  callFindSubstitute: (
    teamId: string,
    shiftId: string,
    personId: number
  ) => void;
  callAssignShift: (
    teamId: string,
    personId: number,
    employeeName: string,
    shiftId: string
  ) => void;
  callMarkAsHandled: (
    teamId: string,
    shiftId: string,
    personId: number
  ) => void;
  callRegisterAbsence: (
    teamId: string,
    shiftId: string,
    absenceTypeId: string,
    returnDate: Date | null
  ) => void;
  callCancelOfferOnShiftExchange: (
    teamId: string,
    personId: number,
    shiftId: string
  ) => void;
  onRemoveShift: () => void;
  setSwapViewOpen: (open: boolean) => void;
  setOfferViewOpen: (open: boolean) => void;
}) {
  const employee = employees[shift.personId];
  // TODO: fix on wi149459
  // const isFictive = !!employee?.isFictive;
  const isFictive = false;
  const canRegisterAbsence =
    !isFictive &&
    (shift.status === TeamShiftStatusEnum.planned ||
      shift.status === TeamShiftStatusEnum.substitute ||
      shift.status === TeamShiftStatusEnum.noActivities) &&
    !shiftIsAssignable;

  // Show original duties if shift is assignable, and not the absense
  const dutyLines = [
    ...(shiftIsAssignable ? shift.dutyLinesOriginal : shift.dutyLines),
  ].sort((a, b) => {
    if (a.start === null && b.start === null) return 0;
    if (a.start === null) return 1;
    if (b.start === null) return -1;
    return a.start - b.start;
  });

  const actionRequired =
    !shiftIsAssignable && shift.status === TeamShiftStatusEnum.actionRequired;

  const assigningCreated = isCreatedShift || isFictive;

  const showGoToSwapOrChooseApplicants =
    shift.status === TeamShiftStatusEnum.offerActionRequired ||
    shift.status === TeamShiftStatusEnum.swapActionRequired;
  const showOfferOnShiftExchange =
    (actionRequired && !isCreatedShift) || (isFictive && !shiftIsAssignable);
  const showFindSubstitute =
    actionRequired || (isFictive && !shiftIsAssignable);
  const showMarkAsHandled = actionRequired && !assigningCreated;

  return (
    <div className="shift">
      <ShiftInfo
        {...{
          absenceTypes,
          dutyLines,
          employees,
          shift,
          shiftIsAssignable,
          shiftRecordType,
          substitute,
          onRemoveShift,
          team,
          canCancelOffer,
          isCreatedShift,
        }}
      >
        {substitute?.assignable && shiftIsAssignable && (
          <Button
            className="assign-shift-button"
            onClick={() => {
              const name = employees[personId]?.name;
              if (name) {
                callAssignShift(team.id, personId, name, shift.id);
              }
            }}
            id={ids.ShiftView.assign}
          >
            {currentLanguage.assignShift}
          </Button>
        )}
      </ShiftInfo>
      {showGoToSwapOrChooseApplicants && (
        <div className="swap-handover-button-container">
          <LoadingButton
            id={ids.ShiftView.goToSwap}
            onClick={() => setSwapViewOpen(true)}
            variant={ButtonStyle.Ghost}
            danger
            icon={Icons.ChevronRight}
            iconPosition={ButtonIconPosition.Right}
            loading={loadingSwap}
          >
            {isSwap
              ? currentLanguage.GoToSwap
              : currentLanguage.ChooseApplicants}
          </LoadingButton>
        </div>
      )}
      {canRegisterAbsence && (
        <RegisterAbsenceSection
          key={shift.id}
          onRegisterAbsenceClick={(absenceTypeId, returnDate) => {
            callRegisterAbsence(team.id, shift.id, absenceTypeId, returnDate);
          }}
          absenceTypes={absenceTypes}
          shift={shift}
          disabled={
            dateFns.differenceInCalendarDays(shift.period.from, currentDate) < 0
          }
          loading={loading}
        />
      )}
      {showOfferOnShiftExchange && (
        <Item
          className="shift-card shift-exchange-item"
          id={ids.ShiftView.exchange}
          onClick={() => {
            setOfferViewOpen(true);
          }}
        >
          <div className={classNames('item-label', { disabled: !swappable })}>
            <Icons.Swap size={IconSize.XSmall} />
            {currentLanguage.offerOnShiftExchange}
          </div>
          {!swappable && (
            <div className="item-warning">
              {currentLanguage.MissingSwapGroup_1(
                employee?.initials.toUpperCase() ||
                  employee?.name ||
                  currentLanguage.Employee
              )}
            </div>
          )}
        </Item>
      )}
      {showFindSubstitute && (
        <Item
          className="shift-card find-substitute-item"
          id={ids.ShiftView.substitute}
          onClick={() => {
            callFindSubstitute(team.id, shift.id, personId);
          }}
        >
          <div className="item-label">
            <Icons.User size={IconSize.XSmall} />
            {assigningCreated
              ? currentLanguage.FindEmployee
              : currentLanguage.findSubstitute}
          </div>
          <Icons.ChevronRight size={IconSize.XXXSmall} />
        </Item>
      )}
      {showMarkAsHandled && (
        <Item
          className="shift-card mark-as-handled-item"
          id={ids.ShiftView.handled}
          onClick={() => {
            callMarkAsHandled(team.id, shift.id, personId);
          }}
        >
          <div className="item-label">
            <Icons.Checkmark size={IconSize.XSmall} />
            {currentLanguage.markAsHandled}
          </div>
        </Item>
      )}
      {canCancelOffer && (
        <Item
          className="shift-card shift-exchange-item"
          id={ids.ShiftView.exchange}
          onClick={() => {
            callCancelOfferOnShiftExchange(team.id, personId, shift.id);
          }}
        >
          <div className="item-label">
            <Icons.Swap size={IconSize.XSmall} />
            {currentLanguage.cancelOfferOnShiftExchange}
          </div>
        </Item>
      )}
    </div>
  );
}
