import { ActionType } from '../ActionType';
import { CallIn } from '../api/TeamPlan_api';
import { CommonAction } from '../commonAction';
import { FindSubstituteViewAction } from './FindSubstituteViewActions';
import { Reducer } from 'redux';

export interface FindSubstituteViewReducer {
  loading: boolean;
  substitutes: Array<CallIn>;
  loadedForShift: string;
}

const initialState: FindSubstituteViewReducer = {
  loading: false,
  substitutes: [],
  loadedForShift: '',
};

const findSubstituteViewReducer: Reducer<FindSubstituteViewReducer> = (
  state: FindSubstituteViewReducer = initialState,
  action: FindSubstituteViewAction | CommonAction
) => {
  switch (action.type) {
    case ActionType.INIT:
      return initialState;
    case 'LOADSUBSTITUES_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'LOADSUBSTITUES_SUCCESS':
      return {
        ...state,
        loading: false,
        substitutes: action.loadSubstitutesResponse,
      };
    case 'LOADSUBSTITUES_FAILURE':
      return {
        ...state,
        loading: false,
      };
    case 'SET_LOADEDFORSHIFT':
      return {
        ...state,
        loadedForShift: action.id,
      };
    case 'RESET_SUBSTITUTE_STORE': {
      return { ...initialState };
    }
  }

  return state;
};

export default findSubstituteViewReducer;

