import * as platform from 'platform';

import { AsyncThunk, Thunk } from './types';
import { GetVersionModel, NotificationLogObj } from './api/model';
import { type DayMessageMap, getChatKey } from './util/getChatKey';

import { ApplicationEnum } from './api/enumLib_api';
import { DayMessage } from './api/Message_api';
import { Dispatch } from 'redux';
import { TeamShiftDef, UserSettings } from './api/TeamPlan_api';
import { ModalWrapperProps } from './Modal/ModalWrapper';
import { dateMath } from '@pdcfrontendui/utils';
import getApi from './getApi';
import { ApiError } from '@pdcfrontendui/staffplan';
import { Holiday } from './api/Calendar_api';
import { ToastType } from '@pdcfrontendui/components/Toast/Toast';
import { ActionType } from './ActionType';
import { ConfirmDialogProps } from './components/ConfirmDialog/ConfirmDialog';
import { InfoDialogProps } from './components/InfoDialog/InfoDialog';
import { GlobalSettings } from './api/Common_api';
import { Location } from 'react-router-dom';
import { Period } from './util/dates';

export type AppAction =
  | {
      type: ActionType.TOGGLE_SCREEN_SMALL;
    }
  | {
      type: ActionType.TOGGLE_SCREEN_BIG;
    }
  | {
      type: ActionType.CHANGE_DATE;
      date: Date;
    }
  | {
      type: ActionType.TOGGLE_CALENDAR;
    }
  | {
      type: ActionType.TOGGLE_SEARCHBAR;
    }
  | {
      type: ActionType.SET_MODALLOADING;
      modalLoading: boolean;
    }
  | {
      type: ActionType.TOGGLE_MENU;
    }
  | {
      type: ActionType.GETVERSION_REQUEST;
    }
  | {
      type: ActionType.GETVERSION_SUCCESS;
      getVersionResponse: GetVersionModel;
    }
  | {
      type: ActionType.GETVERSION_FAILURE;
      errorMessage: string;
    }
  | {
      type: ActionType.SET_VERSION;
      version: GetVersionModel;
    }
  | {
      type: ActionType.GETGLOBALSETTINGS_REQUEST;
    }
  | {
      type: ActionType.GETGLOBALSETTINGS_SUCCESS;
      globalSettings: GlobalSettings;
    }
  | {
      type: ActionType.GETGLOBALSETTINGS_FAILURE;
      errorMessage: string;
    }
  | {
      type: ActionType.GETUSERSETTINGS_REQUEST;
    }
  | {
      type: ActionType.GETUSERSETTINGS_SUCCESS;
      userSettings: UserSettings;
    }
  | {
      type: ActionType.GETUSERSETTINGS_FAILURE;
      errorMessage: string;
    }
  | {
      type: ActionType.OPEN_MODAL;
      modal: ModalWrapperProps;
    }
  | {
      type: ActionType.CLOSE_MODAL;
    }
  | {
      type: ActionType.SET_TOKEN;
      token: string;
    }
  | {
      type: ActionType.SET_TOKEN_TIMESTAMP;
      tokenTimestamp: number;
    }
  | {
      type: ActionType.REMOVE_TOKEN;
    }
  | {
      type: ActionType.SET_APP_LOADING;
      appLoading: boolean;
    }
  | {
      type: ActionType.GETMESSAGES_SUCCESS;
      dayMessageMap: DayMessageMap;
    }
  | {
      type: ActionType.POSTMESSAGE_SUCCESS;
      dayMessage: DayMessage;
    }
  | { type: ActionType.POSTMESSAGE_FAILURE; errorMessage: string }
  | {
      type: ActionType.GETUNREADMESSAGES_SUCCESS;
      unreadCountMap: Record<string, number>;
    }
  | {
      type: ActionType.GOT_HOLIDAYS;
      holidays: Array<Holiday>;
    }
  | {
      type: ActionType.QUEUE_TOAST;
      toast: ToastType;
    }
  | {
      type: ActionType.SHOW_CONFIRM_DIALOG;
      payload: Omit<ConfirmDialogProps, 'shown'>;
    }
  | {
      type: ActionType.HIDE_CONFIRM_DIALOG;
    }
  | {
      type: ActionType.SHOW_INFO_DIALOG;
      payload: Omit<InfoDialogProps, 'shown'>;
    }
  | {
      type: ActionType.HIDE_INFO_DIALOG;
    }
  | {
      type: ActionType.SHOW_ERROR_MODAL;
      error: unknown;
    }
  | {
      type: ActionType.HIDE_ERROR_MODAL;
    }
  | { type: ActionType.LOCATION_CHANGE; payload: Location }
  | { type: ActionType.SET_SHOWING_RULE_VIOLATION_INFO; payload: boolean }
  | { type: ActionType.SET_CREATABLE_SHIFTS; data: TeamShiftDef[] };

export const toggleScreenSmall = (): AppAction => ({
  type: ActionType.TOGGLE_SCREEN_SMALL,
});

export const toggleScreenBig = (): AppAction => ({
  type: ActionType.TOGGLE_SCREEN_BIG,
});

export const changeDate = (date: Date): AppAction => ({
  type: ActionType.CHANGE_DATE,
  date,
});

export const toggleCalendar = (): AppAction => ({
  type: ActionType.TOGGLE_CALENDAR,
});
export const toggleSearchBar = (): AppAction => ({
  type: ActionType.TOGGLE_SEARCHBAR,
});

export const toggleMenu = (): AppAction => ({
  type: ActionType.TOGGLE_MENU,
});

export const setModalLoading = (modalLoading: boolean): AppAction => ({
  type: ActionType.SET_MODALLOADING,
  modalLoading,
});

export const openModal = (modal: ModalWrapperProps): AppAction => ({
  type: ActionType.OPEN_MODAL,
  modal,
});

export const closeModal = (): AppAction => ({
  type: ActionType.CLOSE_MODAL,
});

export const getVersionRequest = (): AppAction => ({
  type: ActionType.GETVERSION_REQUEST,
});

export const getVersionSuccess = (
  getVersionResponse: GetVersionModel
): AppAction => ({
  type: ActionType.GETVERSION_SUCCESS,
  getVersionResponse,
});

export const getVersionFailure = (errorMessage: string): AppAction => ({
  type: ActionType.GETVERSION_FAILURE,
  errorMessage,
});
export const setVersion = (version: GetVersionModel): AppAction => ({
  type: ActionType.SET_VERSION,
  version,
});
export const getGlobalSettingsRequest = (): AppAction => ({
  type: ActionType.GETGLOBALSETTINGS_REQUEST,
});

export const getGlobalSettingsSuccess = (
  globalSettings: GlobalSettings
): AppAction => ({
  type: ActionType.GETGLOBALSETTINGS_SUCCESS,
  globalSettings,
});

export const getGlobalSettingsFailure = (errorMessage: string): AppAction => ({
  type: ActionType.GETGLOBALSETTINGS_FAILURE,
  errorMessage,
});

export const getUserSettingsRequest = (): AppAction => ({
  type: ActionType.GETUSERSETTINGS_REQUEST,
});

export const getUserSettingsSuccess = (
  userSettings: UserSettings
): AppAction => ({
  type: ActionType.GETUSERSETTINGS_SUCCESS,
  userSettings,
});

export const getUserSettingsFailure = (errorMessage: string): AppAction => ({
  type: ActionType.GETUSERSETTINGS_FAILURE,
  errorMessage,
});

export const setToken = (token: string): AppAction => ({
  type: ActionType.SET_TOKEN,
  token,
});

export const setTokenTimestamp = (tokenTimestamp: number): AppAction => ({
  type: ActionType.SET_TOKEN_TIMESTAMP,
  tokenTimestamp,
});

export const getMessagesSuccess = (
  dayMessageMap: DayMessageMap
): AppAction => ({
  type: ActionType.GETMESSAGES_SUCCESS,
  dayMessageMap,
});

export const postMessageFailure = (errorMessage: string): AppAction => ({
  type: ActionType.POSTMESSAGE_FAILURE,
  errorMessage,
});

export const getUnreadMessagesCountSuccess = (
  unreadCountMap: Record<string, number>
): AppAction => ({
  type: ActionType.GETUNREADMESSAGES_SUCCESS,
  unreadCountMap,
});

export const gotHolidays = (holidays: Holiday[]): AppAction => ({
  type: ActionType.GOT_HOLIDAYS,
  holidays,
});

export const queueToast = (toast: ToastType): AppAction => ({
  type: ActionType.QUEUE_TOAST,
  toast,
});

export const showConfirmDialog = (
  payload: Omit<ConfirmDialogProps, 'shown'>
): AppAction => ({
  type: ActionType.SHOW_CONFIRM_DIALOG,
  payload,
});

export const hideConfirmDialog = (): AppAction => ({
  type: ActionType.HIDE_CONFIRM_DIALOG,
});

export const showInfoDialog = (
  payload: Omit<InfoDialogProps, 'shown'>
): AppAction => ({
  type: ActionType.SHOW_INFO_DIALOG,
  payload,
});

export const hideInfoDialog = (): AppAction => ({
  type: ActionType.HIDE_INFO_DIALOG,
});

export const showErrorModal = (error: unknown): AppAction => ({
  type: ActionType.SHOW_ERROR_MODAL,
  error,
});

export const hideErrorModal = (): AppAction => ({
  type: ActionType.HIDE_ERROR_MODAL,
});

export const setCreatableShifts = (data: TeamShiftDef[]): AppAction => ({
  type: ActionType.SET_CREATABLE_SHIFTS,
  data,
});

export function attemptGetVersion() {
  return (dispatch: Dispatch) => {
    dispatch(getVersionRequest());
    return getApi()
      .getVersion()
      .then((systemInformation) => {
        const version: GetVersionModel = {
          ...systemInformation,
          serviceUrl: getApi().baseUrl,
          appVersion: import.meta.env.VITE_TEAMPLAN_VERSION,
        };
        dispatch(getVersionSuccess(version));
      })
      .catch((err) => {
        dispatch(getVersionFailure(err.message));
        const frontendVersion: GetVersionModel = {
          serviceUrl: getApi().baseUrl,
          appVersion: import.meta.env.VITE_TEAMPLAN_VERSION,
          appVersionMin: '',
          dbVersion: '',
          serviceVersion: '',
        };
        dispatch(setVersion(frontendVersion));
      });
  };
}
export function attemptGetGlobalSettings() {
  return (dispatch: Dispatch) => {
    dispatch(getGlobalSettingsRequest());
    return getApi()
      .getGlobalSettings()
      .then((data) => {
        dispatch(getGlobalSettingsSuccess(data));
      })
      .catch((err) => {
        dispatch(getGlobalSettingsFailure(err.message));
      });
  };
}

export function attemptGetUserSettings() {
  return (dispatch: Dispatch) => {
    dispatch(getUserSettingsRequest());
    return getApi()
      .getUserSettings()
      .then((data) => {
        dispatch(getUserSettingsSuccess(data));
      })
      .catch((err) => {
        dispatch(getUserSettingsFailure(err.message));
      });
  };
}

export const attemptSendNotificationLog = (
  error: Error,
  errorInfo?: React.ErrorInfo
): Thunk => {
  const getFirstLines = (input: string) => {
    return input.split('\n').slice(1, 4).join();
  };

  return (dispatch: Dispatch, getState) => {
    let state = getState();
    // if window.pdc.teamplan.buildInfo is not set we are in debug mode
    let appVersion = '0.0.0';
    // If it is an object the teamplan build is versioned
    if (typeof window?.pdc?.teamplan?.buildInfo === 'object') {
      appVersion = window?.pdc?.teamplan?.buildInfo?.version;
    }
    if (typeof appVersion !== 'string') {
      throw new Error('No TeamPlan version on window.pdc.teamplan.buildInfo');
    }
    const notificationLogObj: NotificationLogObj = {
      groupOn: errorInfo
        ? error.name + ': ' + getFirstLines(errorInfo.componentStack)
        : error.name || error.message,
      clientInfo: {
        applicationId: ApplicationEnum.teamPlan,
        version: appVersion,
        browserAgent: platform.description ?? '', // 'Safari 5.1 on Apple iPad (iOS 5.0)'
        browserCodeName: platform.name ?? '', // 'Safari'
        extra: error.stack ?? '',
        platform: platform.os?.toString() ?? '', // 'iOS 5.0'
      },
      exceptionMsg: error.name + ': ' + error.message,
      exceptionName: error.name || error.message,
      userComment: null,
    };

    // Prevent sending notification log if there we are running jest tests.
    // Assume we are if USERNAME exists in the environment
    const isRunningTest = !!(
      typeof process !== 'undefined' &&
      process.env &&
      process.env.USERNAME
    );

    if (isRunningTest) {
      return Promise.resolve();
    }

    return getApi()
      .sendNotificationLog(notificationLogObj)
      .then(() => {})
      .catch((err) => {});
  };
};

export const getHolidays = () => {
  return async (dispatch: Dispatch) => {
    getApi()
      .getHolidays()
      .then((response) => dispatch(gotHolidays(response.Holidays)))
      .catch((err) => {});
  };
};

export function attemptGetMessages(
  personIds: number[],
  from: Date,
  to: Date,
  isPoll: boolean
): AsyncThunk {
  return async (dispatch) => {
    try {
      const dayMessages = await getApi().getMessages(
        personIds,
        from,
        to,
        isPoll
      );
      const messageMap = dayMessages.reduce<DayMessageMap>((acc, curr) => {
        const key = getChatKey(curr.PersonId, curr.Day, curr.Visibility);
        if (acc[key]) {
          acc[key]!.push(curr);
        } else {
          acc[key] = [curr];
        }
        return acc;
      }, {});
      dispatch(getMessagesSuccess(messageMap));
    } catch (err) {
      // Ignore
    }
  };
}

export function attemptPostMessage(
  personId: number,
  messageText: string,
  isInternalMessage: boolean,
  date: Date
): AsyncThunk {
  return async (dispatch) => {
    try {
      await getApi().postMessage(
        date,
        personId,
        messageText,
        isInternalMessage
      );
    } catch (err) {
      if (err instanceof ApiError) {
        dispatch(postMessageFailure(err.message));
      }
    } finally {
      const floored = dateMath.floorDay(date);
      // If success: Call get messages to make sure any inconsistencies between the server and the GUI are resolved
      // If error: Call get messages so as to invalidate (remove) the message that was added to the GUI when the post request happened
      await dispatch(
        attemptGetMessages(
          [personId],
          floored,
          dateMath.plusDay(floored),
          false
        )
      );
    }
  };
}

export function attemptMarkMessagesAsRead(messageIds: number[]): AsyncThunk {
  return async (dispatch, getState) => {
    try {
      const { listViewReducer } = getState();
      const shift =
        listViewReducer.shiftMap[listViewReducer.currentTeam]?.[
          listViewReducer.selectedShiftId
        ];
      await getApi().markMessagesAsRead(messageIds);
      // Call GetMessages right away to make sure we are showing the right counters. Otherwise we would have to wait for the next periodical GetMessages call.
      if (shift) {
        const floored = dateMath.floorDay(shift.period.from);
        await dispatch(
          attemptGetMessages(
            [shift.personId],
            floored,
            dateMath.plusDay(floored),
            false
          )
        );
      }
    } catch (err) {
      // Ignore
    }
  };
}

export function atteptGetUnreadMessagesCount(
  teamIds: string[],
  from: Date,
  to: Date,
  isPoll: boolean
): AsyncThunk {
  return async (dispatch) => {
    try {
      if (teamIds.length > 0) {
        const unreadCounts = await getApi().getUnreadMessagesCount(
          teamIds,
          from,
          to,
          isPoll
        );
        const unreadCountMap = unreadCounts.reduce<Record<string, number>>(
          (acc, curr) => {
            acc[curr.TeamId] = curr.NumberOfUnreadMessages;
            return acc;
          },
          {}
        );
        dispatch(getUnreadMessagesCountSuccess(unreadCountMap));
      }
    } catch (err) {
      // Ignore
    }
  };
}

export function setLocationForTracking(location: Location): AppAction {
  return {
    type: ActionType.LOCATION_CHANGE,
    payload: location,
  };
}

export function setShowingRuleViolationInfo(
  setShowingInfo: boolean
): AppAction {
  return {
    type: ActionType.SET_SHOWING_RULE_VIOLATION_INFO,
    payload: setShowingInfo,
  };
}

export function attemptGetShiftDefs(
  teamId: string,
  period: Period
): AsyncThunk {
  return async (dispatch) => {
    try {
      const shiftDefs = await getApi().getShiftDefs(teamId, period);
      dispatch(setCreatableShifts(shiftDefs));
    } catch (err) {
      // Ignore
    }
  };
}
