import { AsyncThunk, ThunkDispatch } from '../types';

import { CallIn } from '../api/TeamPlan_api';
import { attemptSendNotificationLog } from '../appActions';
import getApi from '../getApi';
import {
  CreatedShift,
  isCreatedShift,
} from '../ListView/CreateShift/EditedShift';

export type FindSubstituteViewAction =
  | {
      type: 'LOADSUBSTITUES_REQUEST';
    }
  | {
      type: 'LOADSUBSTITUES_SUCCESS';
      loadSubstitutesResponse: Array<CallIn>;
    }
  | {
      type: 'LOADSUBSTITUES_FAILURE';
      errorMessage: string;
    }
  | {
      type: 'SET_LOADEDFORSHIFT';
      id: string;
    }
  | {
      type: 'RESET_SUBSTITUTE_STORE';
    };

export const loadSubstitutesRequest = (): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUES_REQUEST',
});

export const loadSubstitutesSuccess = (
  loadSubstitutesResponse: Array<CallIn>
): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUES_SUCCESS',
  loadSubstitutesResponse,
});

export const loadSubstitutesFailure = (
  errorMessage: string
): FindSubstituteViewAction => ({
  type: 'LOADSUBSTITUES_FAILURE',
  errorMessage,
});

export const setLoadedForShift = (id: string): FindSubstituteViewAction => ({
  type: 'SET_LOADEDFORSHIFT',
  id,
});

export const resetSubstituteStore = (): FindSubstituteViewAction => ({
  type: 'RESET_SUBSTITUTE_STORE',
});

export function attemptLoadSubstitutes(shiftId: string, teamId: string) {
  return (dispatch: ThunkDispatch) => {
    dispatch(loadSubstitutesRequest());
    // TODO make request here for substitues
    return getApi()
      .getCallIn(shiftId, teamId)
      .then((substitutes) => {
        dispatch(loadSubstitutesSuccess(substitutes));
      })
      .catch((err) => {
        dispatch(loadSubstitutesFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      });
  };
}

export function attemptGetShiftCandidateList(teamId: string): AsyncThunk {
  return async (dispatch, getState) => {
    try {
      const editedShift = getState().shiftViewReducer.editedShift;
      if (!editedShift || !isCreatedShift(editedShift)) {
        console.error('No edited shift found');
        return;
      }
      dispatch(loadSubstitutesRequest());
      const substitutes = await getApi().getShiftCandidateList(
        teamId,
        editedShift
      );
      dispatch(loadSubstitutesSuccess(substitutes));
    } catch (err) {
      if (err instanceof Error) {
        dispatch(loadSubstitutesFailure(err.message));
        dispatch(attemptSendNotificationLog(err));
      }
    }
  };
}

