import * as React from 'react';
import { currentLanguage } from '../currentLanguage';

import { langMap } from '../generatedLanguages';

import './LanguageSelect.scss';
import ids from '../testing/ids';
import { SelectInput, SelectOptions } from '@pdcfrontendui/components';

export interface DispatchFromProps {
  setLanguage: (newLanguage: string) => void;
}

export interface StateFromProps {
  language: string;
}

type Props = StateFromProps & DispatchFromProps;

export default class LanguageSelect extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.options = Object.keys(langMap).map<SelectOptions>((key) => {
      const obj = langMap[key as keyof typeof langMap];
      return {
        key: obj.languageCode,
        value: obj.languageName,
      };
    });
  }
  options: SelectOptions[];

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let lang = event.target.value;
    if (langMap[lang as keyof typeof langMap]) {
      this.props.setLanguage(lang);
    }
  };

  render() {
    return (
      <SelectInput
        id={ids.Login.languageSelect}
        value={currentLanguage.languageCode}
        label=""
        ghost
        onChange={this.handleChange}
        options={this.options}
        ariaLabel={currentLanguage.SelectLanguage}
      />
    );
  }
}
