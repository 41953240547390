import Icons, { IconSize } from '@pdcfrontendui/components/Icons';
import {
  MessageViewQueries,
  Tab,
  getMessageRouteParams,
  getSiteRoutes,
  useMessageRouteParams,
} from '../routes';
import { ModalType, ModalWrapperProps } from '../Modal/ModalWrapper';
import React, { useMemo, useState } from 'react';
import { dateFormats, dateMath, makeKeyDown } from '@pdcfrontendui/utils';
import { hasUnreadMessages, shouldShowAsUnread } from '../Chat/chatUtils';

import ChatThread from '@pdcfrontendui/components/ChatThread';
import { CounterVariant } from '@pdcfrontendui/components';
import { DAYS_LOADED_DEFAULT } from '../constants';
import { DayMessage } from '../api/Message_api';
import { DayMessageMap } from '../util/getChatKey';
import { EmployeeMap } from '../api/model';
import Header from '@pdcfrontendui/components/Header';
import { MsgVisibilityOldEnum } from '../api/enumLib_api';
import NoMessages from '../components/NoMessages';
import { Period } from '../util/dates';
import Tabs from '@pdcfrontendui/components/Tabs';
import classNames from 'classnames';
import { currentLanguage } from '../currentLanguage';
import ids from '../testing/ids';
import { isWithinRange } from 'date-fns';
import scss from './MessageList.module.scss';
import { useIsDualView } from '@pdcfrontendui/hooks';
import useNotesAndMessages from '../hooks/useNotesAndMessages';
import { useLocation, useNavigate } from 'react-router-dom';

export type StateFromProps = {
  dayMessageMap: DayMessageMap;
  employees: EmployeeMap;
  userId: number;
  teamId: string;
  currentDate: Date;
  period: Period;
};

export type DispatchFromProps = {
  openModal: (modal: ModalWrapperProps) => void;
  callPostMessage: (
    personId: number,
    messageText: string,
    isInternalMessage: boolean,
    date: Date
  ) => void;
  callMarkMessagesAsRead: (messageIds: number[]) => void;
};

const MessageList = ({
  dayMessageMap,
  employees,
  userId,
  teamId,
  currentDate,
  openModal,
  callPostMessage,
  callMarkMessagesAsRead,
  period,
}: StateFromProps & DispatchFromProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const screensizeBig = useIsDualView();
  const [personId, setPersonId] = useState(-1);
  const [visibility, setVisibility] = useState(MsgVisibilityOldEnum.employee);
  const [date, setDate] = useState(dateMath.BEGINNING_OF_TIME);
  const listViewClassName = screensizeBig ? 'splitpane-left' : 'splitpane-one';
  const { tab = Tab.Unread, messageId = undefined } = useMessageRouteParams();
  const { messages, markMessagesAsRead, sendMessage } = useNotesAndMessages(
    userId,
    dayMessageMap,
    date,
    personId,
    false,
    callMarkMessagesAsRead,
    callPostMessage,
    visibility
  );

  const employeekeys = useMemo(() => Object.keys(employees), [employees]);

  // Array of threads, sorted by last message sent
  const threads = useMemo(
    () =>
      Object.values(dayMessageMap)
        .filter(
          (thread) =>
            isWithinRange(thread[0].Day, period.from, period.to) &&
            employeekeys.includes(thread[0].PersonId.toString())
        )
        .sort(
          (a, b) =>
            a[a.length - 1]!.SentWhen.getTime() -
            b[b.length - 1]!.SentWhen.getTime()
        )
        .reverse(),
    [dayMessageMap]
  );

  const unreadCount = useMemo(
    () =>
      threads.flatMap((thread) =>
        thread.filter((message) => shouldShowAsUnread(message, userId))
      ).length,
    [threads, userId]
  );

  const hasRead = useMemo(
    () => threads.some((messages) => !hasUnreadMessages(messages, userId)),
    [threads, userId]
  );

  const messageTabsArr = [Tab.Unread, Tab.Read] as const;
  function getChatIcon(message: DayMessage) {
    if (message.Visibility === MsgVisibilityOldEnum.planner) {
      return <Icons.ChatLock size={IconSize.XSmall} />;
    } else {
      return <Icons.Chat size={IconSize.XSmall} />;
    }
  }

  const onGoBack = () => {
    updateChatParams({ messageId: undefined });
    setPersonId(-1);
  };

  const updateChatParams = (updates: MessageViewQueries) => {
    navigate(
      getSiteRoutes().chat(teamId, {
        ...getMessageRouteParams(location.search),
        ...updates,
      })
    );
  };

  const onThreadClick = (message: DayMessage) => {
    setPersonId(message.PersonId);
    setVisibility(message.Visibility);
    setDate(message.Day);
    updateChatParams({
      messageId: String(message.MessageId),
    });
  };

  const shouldShowInList = (thread: DayMessage[]) =>
    (tab === Tab.Read && !hasUnreadMessages(thread, userId)) ||
    (tab === Tab.Unread && hasUnreadMessages(thread, userId));

  const isShiftSingleView = !screensizeBig && messageId;
  const messageViewClassName = !screensizeBig
    ? 'splitpane-one'
    : 'splitpane-right';
  return (
    <div className="splitpane">
      {!isShiftSingleView && (
        <div className={listViewClassName}>
          <Header centerize>
            <Header.Title bold>
              {currentLanguage.ChatAndInternalMessages}
            </Header.Title>
            <Header.Right>
              <Icons.Info
                size={IconSize.XSmall}
                onClick={() =>
                  openModal({
                    modalType:
                      tab === Tab.Unread
                        ? ModalType.chatUnread
                        : ModalType.chatRead,
                    modalBackText: currentLanguage.Close,
                    content:
                      tab === Tab.Unread
                        ? currentLanguage.MessageModalUnread_1(
                            DAYS_LOADED_DEFAULT
                          )
                        : currentLanguage.MessageModalRead_1(
                            DAYS_LOADED_DEFAULT
                          ),
                  })
                }
              />
            </Header.Right>
          </Header>

          <Tabs
            tabsArr={messageTabsArr}
            selectedTab={tab}
            setSelectedTab={(tab: Tab) =>
              updateChatParams({ tab, messageId: undefined })
            }
            tabLabelMap={{
              [Tab.Unread]: currentLanguage.Unread,
              [Tab.Read]: currentLanguage.Read,
            }}
            counterMap={{
              [Tab.Unread]: {
                count: unreadCount,
                variant: CounterVariant.Default,
              },
            }}
            idMap={{
              [Tab.Unread]: ids.ShiftView.unreadMessagesTab,
              [Tab.Read]: ids.ShiftView.readMessagesTab,
            }}
          />
          {(tab === Tab.Read && !hasRead) ||
          (tab === Tab.Unread && unreadCount === 0) ? (
            <NoMessages />
          ) : (
            <ul className={scss.MessageList}>
              {threads.map(
                (thread, index) =>
                  shouldShowInList(thread) && (
                    <li
                      key={index}
                      className={classNames({
                        [scss.selected ?? '']:
                          messageId === String(thread[0].MessageId),
                      })}
                      role="button"
                      onClick={() => onThreadClick(thread[0])}
                      onKeyDown={makeKeyDown(() => onThreadClick(thread[0]))}
                      tabIndex={0}
                    >
                      <div>
                        <div>{getChatIcon(thread[0])} </div>
                        <div>{employees[`${thread[0].PersonId}`]?.name}</div>
                      </div>
                      <div>
                        <div>
                          {`${
                            currentLanguage.RegardingShort
                          } ${dateFormats.Man_31DOT_okt(thread[0].Day)}`}
                        </div>
                        <div>
                          {dateMath.floorDay(currentDate).valueOf() ===
                          dateMath.floorDay(thread[0].SentWhen).valueOf()
                            ? dateFormats.$23DOT59(thread[0].SentWhen)
                            : dateFormats.Man_31DOT_okt(thread[0].SentWhen)}
                        </div>
                      </div>
                    </li>
                  )
              )}
            </ul>
          )}
        </div>
      )}
      {(isShiftSingleView || screensizeBig) && (
        <div className={messageViewClassName}>
          {messageId ? (
            <>
              <Header centerize className={scss.header}>
                {screensizeBig && <Header.Spacer />}
                {!screensizeBig && (
                  <Header.Left>
                    <Icons.ChevronLeft
                      onClick={onGoBack}
                      id={ids.ShiftView.closeChat}
                      size={IconSize.XSmall}
                    />
                  </Header.Left>
                )}
                <Header.Title
                  bold={!screensizeBig}
                >{`${currentLanguage.ChatWith_1(
                  employees[personId]?.name.split(' ')[0] ??
                    currentLanguage.Employee
                )}, ${dateFormats
                  .Man_31DOT_okt(date)
                  .toLowerCase()}`}</Header.Title>
              </Header>
              <ChatThread
                messages={messages ?? []}
                sendMessage={sendMessage}
                placeholder={currentLanguage.WriteMessage}
                markAllAsRead={markMessagesAsRead}
                inputId={ids.ShiftView.messageInput}
                sendButtonId={ids.ShiftView.sendMessage}
                maxLength={800}
                maxLengthError={currentLanguage.MaxLengthError}
              />
            </>
          ) : (
            <>
              <Header />
              <Header />
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default MessageList;
