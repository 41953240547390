import {
  Button,
  ButtonStyle,
  LoadingButton,
  MobileSlideIn,
  ReadOnlyField,
  SelectInput,
  TimeInput,
} from '@pdcfrontendui/components';
import React, { useRef } from 'react';
import { dateFormats, dayKey } from '@pdcfrontendui/utils';
import { localStorageSet, useLocalStorage } from '../../util/localStorage';

import { EditedShift, isCreatedShift } from './EditedShift';
import Header from '@pdcfrontendui/components/Header';
import Icons from '@pdcfrontendui/components/Icons';
import { SearchableSelect } from '@pdcfrontendui/components';
import { LocalStorageKey } from '../../util/LocalStorageKey';
import { TeamShiftDef } from '../../api/TeamPlan_api';
import { periodToMinuteOffsetInterval } from '../../util/minuteOffset';
import classNames from 'classnames';
import { currentLanguage } from '../../currentLanguage';
import scss from './EditShiftForm.module.scss';
import getApi from '../../getApi';
import ButtonContainer, {
  Align,
} from '@pdcfrontendui/components/ButtonContainer';
import { useTeamId } from '../../routes';
import { useEditShift } from './useEditShift';

function EditShiftForm({
  isMobile,
  show,
  goBack,
  today,
  className,
  deleteShift,
  title,
  backText,
  submitText,
  setEditedShift,
  editedShift,
  shiftTypes: shiftDefs,
}: {
  isMobile: boolean;
  show: boolean;
  goBack: () => void;
  today: Date;
  className?: string;
  deleteShift?: () => void;
  title: string;
  backText?: string;
  submitText: string;
  setEditedShift: (editedShift: EditedShift | null) => void;
  editedShift: EditedShift | null;
  shiftTypes: TeamShiftDef[];
}) {
  const teamId = useTeamId();
  const ref = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const toHoursRef = useRef<HTMLInputElement>(null!);

  const shiftTypeSuggestionsMap = useLocalStorage(
    LocalStorageKey.shiftTypeSuggestions
  );

  const onSubmit = async (draft: EditedShift) => {
    const interval = periodToMinuteOffsetInterval(draft);
    if (
      isCreatedShift(draft) &&
      draft.def.editable &&
      (draft.def.startTime !== interval.from ||
        draft.def.endTime !== interval.to)
    ) {
      if (draft.def.dutyLines.length > 1) {
        try {
          // Check for how the dutylines will be affected by the change
          const dutyLines = await getApi().getModifiedDutyLines(draft);
          draft = { ...draft, dutyLines };
        } catch (e) {
          return;
        }
      } else {
        // We know that the time is edited and that there is only one duty line, so we don't need to ask the backend for new duty lines. We just update it.
        draft = {
          ...draft,
          dutyLines: draft.def.dutyLines.map((dutyLine) => ({
            ...dutyLine,
            start: interval.from,
            end: interval.to,
          })),
        };
      }
    }
    setEditedShift(draft);
  };

  const {
    fromHours,
    fromMinutes,
    setFromHours,
    setFromMinutes,
    setToHours,
    setToMinutes,
    shiftTypeKey,
    toHours,
    toMinutes,
    setExtendBy,
    isOverlapping,
    startDate,
    endDate,
    isValid,
    setShiftTypeKey,
    submitForm,
    setStartDate,
    extendByOptions,
    shiftOptions,
    shouldShowEndDateSelect,
    showEndDateSelect,
    startDateOptions,
    timeEditable,
    canSelectEndDate,
    extendNumDays,
    isSubmitting,
  } = useEditShift(
    today,
    shiftDefs,
    editedShift,
    onSubmit,
    teamId,
    shiftTypeSuggestionsMap,
    (map) => localStorageSet(LocalStorageKey.shiftTypeSuggestions, map)
  );

  const submitDisabled =
    !isValid || !fromHours || !fromMinutes || !toHours || !toMinutes;

  return (
    <MobileSlideIn
      isMobile={isMobile}
      className={classNames(scss.comp, className)}
      ref={ref}
      absolute
      bottom={50}
    >
      {show && (
        <>
          <Header centerize className={scss.header}>
            {!isMobile && <Header.Spacer />}
            {isMobile && (
              <Header.Left>
                {backText ? (
                  <Button onClick={goBack} variant={ButtonStyle.GhostOnDark}>
                    {backText}
                  </Button>
                ) : (
                  <Icons.ChevronLeft onClick={goBack} />
                )}
              </Header.Left>
            )}
            <Header.Title bold={isMobile}>{title}</Header.Title>
            {isMobile && (
              <Header.Right>
                <LoadingButton
                  loading={isSubmitting}
                  onClick={() => void submitForm()}
                  variant={ButtonStyle.GhostOnDark}
                  disabled={submitDisabled}
                >
                  {submitText}
                </LoadingButton>
              </Header.Right>
            )}
          </Header>
          <div className={scss.body} ref={bodyRef}>
            <div className={scss.form}>
              <SelectInput
                required
                label={currentLanguage.Date}
                options={startDateOptions}
                value={dayKey(startDate)}
                onChange={(e) => {
                  void setStartDate(new Date(e.target.value));
                }}
              />
              <SearchableSelect
                containedIn={bodyRef}
                required
                label={currentLanguage.Type}
                options={shiftOptions}
                value={shiftTypeKey}
                noSearchResultsText={currentLanguage.NoShiftMatch}
                placeholder={currentLanguage.ChoosePredefined}
                expandAriaLabel={currentLanguage.SelectShiftType}
                onChange={(key) => void setShiftTypeKey(key)}
              />
              <div className={scss.fromTo}>
                <TimeInput
                  required={timeEditable}
                  hours={fromHours}
                  label={currentLanguage.From}
                  minutes={fromMinutes}
                  onHoursChange={(value) => void setFromHours(value)}
                  onMinutesChange={(value) => void setFromMinutes(value)}
                  focusOnComplete={toHoursRef}
                  disabled={!timeEditable}
                  ghost={!timeEditable}
                  appearDisabled={false}
                />
                <TimeInput
                  required={timeEditable}
                  hours={toHours}
                  label={currentLanguage.To}
                  minutes={toMinutes}
                  onHoursChange={(value) => void setToHours(value)}
                  onMinutesChange={(value) => void setToMinutes(value)}
                  hoursRef={toHoursRef}
                  disabled={!timeEditable}
                  ghost={!timeEditable}
                  appearDisabled={false}
                />
                {canSelectEndDate ? (
                  !shouldShowEndDateSelect ? (
                    <Button
                      className={scss.showEndDate}
                      onClick={showEndDateSelect}
                      variant={ButtonStyle.Ghost}
                    >
                      {currentLanguage.AddEndDate}
                    </Button>
                  ) : (
                    <SelectInput
                      required
                      label={currentLanguage.EndDate}
                      className={scss.selectEndDate}
                      options={extendByOptions}
                      value={extendNumDays.toString()}
                      onChange={(e) => void setExtendBy(e.target.value)}
                    />
                  )
                ) : (
                  isOverlapping && (
                    <ReadOnlyField
                      value={dateFormats.$31DOT_okt(endDate)}
                      label={currentLanguage.EndDate}
                      ghost
                    />
                  )
                )}
              </div>
              {deleteShift && (
                <Button
                  variant={ButtonStyle.Ghost}
                  onClick={deleteShift}
                  danger
                >
                  {currentLanguage.Delete}
                </Button>
              )}
              {!isMobile && (
                <ButtonContainer align={Align.Right}>
                  <Button onClick={goBack} variant={ButtonStyle.Ghost}>
                    {backText}
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    onClick={() => void submitForm()}
                    variant={ButtonStyle.Primary}
                    disabled={submitDisabled}
                  >
                    {submitText}
                  </LoadingButton>
                </ButtonContainer>
              )}
            </div>
          </div>
        </>
      )}
    </MobileSlideIn>
  );
}
export default EditShiftForm;
